import { Microphone, useActiveSpeakersState, useAttendeeStatus } from "amazon-chime-sdk-component-library-react"
import { AvatarWithDefault } from "../../../ui/AvatarWithDefault"
import { AvatarContainer, AvatarNameLabel, AvatarNamePlate, RemoteAvatarRoot } from "./RemoteAvatar.styled"
import { useContext } from "react"
import { IconHandYellow } from "../../../ui/Icons"
import { RaisedHandContext } from "../../ConferenceRoom/ConferenceRoom"

interface RemoteAvatarProps {
    attendee: any
    tileId: number
}

function RemoteAvatar(props: RemoteAvatarProps) {
    const { attendee } = props
    const { muted } = useAttendeeStatus(attendee.chimeAttendeeId)
    const activeSpeakers = useActiveSpeakersState()
    const raisedHands = useContext(RaisedHandContext)

    return (
        <RemoteAvatarRoot
            isHandRaised={raisedHands && raisedHands.includes(attendee.id)}
            isSpeaking={(!muted && activeSpeakers && activeSpeakers.indexOf(attendee.chimeAttendeeId) > -1) || false}
        >
            <AvatarContainer
                isHandRaised={raisedHands && raisedHands.includes(attendee.id)}
                isSpeaking={(!muted && activeSpeakers && activeSpeakers.indexOf(attendee.chimeAttendeeId) > -1) || false}
            >
                <AvatarWithDefault
                    size={80}
                    badgesize={80}
                    alt={attendee.name ? attendee.name : ""}
                    src={attendee.avatarUrl}
                    backgroundSize="cover"
                />
            </AvatarContainer>

            <AvatarNamePlate>
                {raisedHands && raisedHands.includes(attendee.id) && <IconHandYellow height="18px" width="18px" />}
                <AvatarNameLabel>{attendee.name}</AvatarNameLabel>
                <Microphone muted={muted} width="25px" height="25px" />
            </AvatarNamePlate>
        </RemoteAvatarRoot>
    )
}

export default RemoteAvatar
