import * as React from "react"
import { Modal } from "react-bootstrap"
import styled from "styled-components"
import branding from "../../branding/branding"
import { IconClose } from "../Icons"
import { SubmitButtonContainer, DisagreeButton, SubmitButtonNew } from "./SayHelloModal"

const DeleteModal = styled(Modal)`
    background-color: rgba(0, 0, 0, 0.3);
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};

    .modal-content {
        font-family: ${branding.font1};
        padding: 0 20px;
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
        padding: 35px 0 20px 0;
    }
    .modal-footer {
        justify-content: space-between;
        border: none;
        padding: 0px 8px;
    }

    .modal-body {
        padding: 15px 15px 30px 15px;
    }

    .modal-title {
        font-family: ${branding.font1};
        margin-left: 15px;
        font-size: 18px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 3px;
    }
`

// const DeleteButton = styled(Button)`
//     margin-left: 16px;
//     border-radius: 20px;
//     font-size: 12px;

// `
const DeleteMeetingTitle = styled.span`
    /* font-family: ${branding.font1};
 color: ${branding.mainInfoColor ?? "#000"}; */
`

const CloseButton = styled.div`
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;
    svg {
        color: ${branding.mainInfoColor};
    }
`

const DeleteMeetingRoot = styled.div`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor ?? "#000"};
    display: absolute;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
`

interface DeleteCalendarEntryModalProps {
    type: "cancel" | "decline"
    backdrop: string
    animation: boolean
    show: boolean
    deleteCalendarEntry: () => void
    setShowConfirmationDialog: (show: boolean) => void
}

const DeleteCalendarEntryModal: React.FC<DeleteCalendarEntryModalProps> = (props) => {
    const modalTitle =
        props.type === "cancel"
            ? branding.calendarEntryModalPageContent.deleteMeeting
            : branding.calendarEntryListView.declineButtonTitle

    const modalText =
        props.type === "cancel"
            ? branding.calendarEntryModalPageContent.cancelText
            : branding.calendarEntryListView.declineMeetingText

    const modalButtonTitle =
        props.type === "cancel"
            ? branding.calendarEntryModalPageContent.deleteMeeting
            : branding.calendarEntryListView.declineTitle

    return (
        <DeleteModal centered backdrop={props.backdrop} animation={props.animation} show={props.show}>
            <Modal.Header>
                <Modal.Title>
                    <DeleteMeetingTitle>{modalTitle}</DeleteMeetingTitle>
                </Modal.Title>
                <CloseButton
                    onClick={(e: any) => {
                        e.preventDefault()
                        e.stopPropagation()
                        props.setShowConfirmationDialog(false)
                    }}
                >
                    {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                </CloseButton>
            </Modal.Header>
            <Modal.Body>
                <DeleteMeetingRoot>{modalText}</DeleteMeetingRoot>
            </Modal.Body>
            <Modal.Footer>
                <SubmitButtonContainer>
                    <DisagreeButton
                        className="d-flex align-items-center"
                        onClick={() => {
                            props.setShowConfirmationDialog(false)
                        }}
                    >
                        {branding.calendarEntryModalPageContent.cancel}
                    </DisagreeButton>
                    <SubmitButtonNew
                        type="submit"
                        onClick={() => props.deleteCalendarEntry()}
                        className="d-flex align-items-center justify-content-center"
                    >
                        {modalButtonTitle}
                    </SubmitButtonNew>
                </SubmitButtonContainer>
            </Modal.Footer>
        </DeleteModal>
    )
}

export default DeleteCalendarEntryModal
