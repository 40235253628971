// Docs:
// https://github.com/aws/amazon-chime-sdk-js/blob/main/guides/15_Background_Filter_Video_Processor.md#adding-a-background-filter-to-your-application

import { SyntheticEvent, useEffect, useRef, useState } from "react"
import {
    DevicePermissionMessage,
    DevicePermissionMessageContainer,
    DeviceSettingsRoot,
    SettingsGroup
} from "../AudioSettings/AudioSettings.styled"
import { PreviewVideoContainer, PreviewVideoOverlay } from "../VideoSettings/VideoSettings.styled"

import ToggleSwitch from "../../../ui/ToggleSwitch/ToggleSwitch"
import { detect } from "detect-browser"
import { SettingsLabel, SettingsSectionTitle } from "../Settings/Settings.styled"
import { Col, Container, Row } from "react-bootstrap"
import { IconCameraOff, IconTrash, IconUpload } from "../../../ui/Icons"
import {
    BackgroundGalleryContainer,
    BackgroundGalleryItem,
    CustomBackgroundActionsOverlay,
    IconContainer,
    NoContentShift,
    ToggleGroup,
    TrashIconContainer
} from "./BackgroundSettings.styled"
import PreviewVideo from "../PreviewVideo/PreviewVideo"

import branding from "../../../branding/branding"
import { useVideoContext } from "../../context/VideoContext"
import { usePreMeetingSettingContext } from "../../context/PreMeetingSettingsContext"
import { useDevicesV2 } from "../../hooks/useDevices"
import { UserMediaItem } from "../../../backendServices/UserMediaServices"
import SettingsErrorMessage from "../../../conference/components/settings/SettingsErrorMessage/SettingsErrorMessage"

function BackgroundSettings() {
    const {
        getBackgroundGalleryItems,
        setSelectedBackground,
        getSelectedBackground,
        removeBackground,
        toggleBlur,
        getIsBlurActive,
        uploadBackground,
        getCustomUploadedBackgrounds,
        deleteCustomBackground,
        getErrorMessage,
        resetErrorMessage
    } = useVideoContext()
    const browserInfo = detect()
    const backgroundInputRef = useRef<HTMLInputElement | null>(null)
    const preMeetingSettingsContext = usePreMeetingSettingContext()
    const [hasCameraPermission, setHasCameraPermission] = useState(true)
    const devices = useDevicesV2()

    useEffect(() => {
        preMeetingSettingsContext.applyStoredVideoSettings()
        return () => {
            resetErrorMessage()
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        devices.currentVideoInputDevice() ? setHasCameraPermission(true) : setHasCameraPermission(false)
        // eslint-disable-next-line
    }, [devices.currentVideoInputDevice()])

    return (
        <>
            {hasCameraPermission ? (
                <DeviceSettingsRoot>
                    <SettingsGroup>
                        <PreviewVideoContainer>
                            <NoContentShift browser={browserInfo?.name}>
                                <PreviewVideo />
                                <PreviewVideoOverlay>
                                    <span>{branding.audioVideoSettings.preview}</span>
                                </PreviewVideoOverlay>
                            </NoContentShift>
                        </PreviewVideoContainer>

                        <ToggleGroup>
                            <SettingsLabel>{branding.audioVideoSettings.radioBlurBackground}</SettingsLabel>
                            <ToggleSwitch
                                scaleSize={0.7}
                                isEnabled={getIsBlurActive()}
                                onClick={async () => {
                                    toggleBlur()
                                }}
                            />
                        </ToggleGroup>
                    </SettingsGroup>

                    <SettingsGroup>
                        <Container className="p-0">
                            <Row>
                                <Col>
                                    <SettingsSectionTitle>
                                        {branding.audioVideoSettings.backgroundGalleryTitleLabel}
                                    </SettingsSectionTitle>
                                </Col>
                                <Col>
                                    <IconContainer
                                        onClick={() => {
                                            // this fixes problem with reuploading same image not working
                                            if (backgroundInputRef.current) {
                                                backgroundInputRef.current.value = ""
                                            }
                                            backgroundInputRef.current?.click()
                                        }}
                                    >
                                        {IconUpload({ fill: "#fff", width: "15px", height: "15px" })}
                                    </IconContainer>
                                    <input
                                        onChange={(e: SyntheticEvent) => {
                                            uploadBackground(e)
                                        }}
                                        ref={backgroundInputRef}
                                        type="file"
                                        accept="image/png, image/jpg, image/jpeg"
                                        style={{ display: "none" }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <BackgroundGalleryContainer>
                                        <BackgroundGalleryItem
                                            isActive={!getSelectedBackground() && !getIsBlurActive()}
                                            onClick={() => removeBackground()}
                                            image={""}
                                        >
                                            {branding.audioVideoSettings.radioNoBackground}
                                        </BackgroundGalleryItem>

                                        {getBackgroundGalleryItems().map((background, key) => {
                                            return (
                                                <BackgroundGalleryItem
                                                    onClick={() => {
                                                        if (getSelectedBackground() === background.url) return
                                                        setSelectedBackground(background)
                                                    }}
                                                    key={key}
                                                    isActive={getSelectedBackground() === background.url}
                                                    image={background.url}
                                                >
                                                    {getCustomUploadedBackgrounds()
                                                        .map((item: UserMediaItem) => item.url)
                                                        .includes(background.url) && (
                                                        <>
                                                            <CustomBackgroundActionsOverlay
                                                                key={key}
                                                                onClick={() => {
                                                                    if (background.url === getSelectedBackground()) return
                                                                    setSelectedBackground(background)
                                                                }}
                                                            ></CustomBackgroundActionsOverlay>

                                                            <div className="actions">
                                                                <TrashIconContainer
                                                                    key={key}
                                                                    onClick={() => {
                                                                        deleteCustomBackground(background.url)
                                                                    }}
                                                                >
                                                                    {IconTrash({
                                                                        width: "12px",
                                                                        height: "12x",
                                                                        fill: "#fff"
                                                                    })}
                                                                </TrashIconContainer>
                                                            </div>
                                                        </>
                                                    )}
                                                </BackgroundGalleryItem>
                                            )
                                        })}
                                    </BackgroundGalleryContainer>
                                </Col>
                            </Row>
                        </Container>
                        <Container className="p-0 pt-2">
                            <Row>
                                <Col>
                                    <SettingsErrorMessage message={getErrorMessage()} />
                                </Col>
                            </Row>
                        </Container>
                    </SettingsGroup>
                </DeviceSettingsRoot>
            ) : (
                <DeviceSettingsRoot>
                    <DevicePermissionMessageContainer>
                        <DevicePermissionMessage>
                            <IconCameraOff fill="#fff" width="25px" height="25px" />
                        </DevicePermissionMessage>
                        <DevicePermissionMessage>
                            {branding.communicationAreaAudioVideoSettingsVideoPermissionMessage}
                        </DevicePermissionMessage>
                    </DevicePermissionMessageContainer>
                </DeviceSettingsRoot>
            )}
        </>
    )
}

export default BackgroundSettings
