import { SettingsErrorMessageWrapper } from "./SettingsErrorMessage.styled"

interface SettingsErrorMessageProps {
    message: string | undefined
}

function SettingsErrorMessage(props: SettingsErrorMessageProps) {
    if (!props.message) return null

    return <SettingsErrorMessageWrapper>{props.message}</SettingsErrorMessageWrapper>
}

export default SettingsErrorMessage
