import styled from "styled-components"
import branding from "../../../branding/branding"
import { TopBarMode } from "../../../navigationArea/TopBar"
import { device } from "../../../utils/Device"

export const DetailPageContentRoot = styled.div`
    padding: 0px;
    font-family: ${branding.font1};

    @media (max-width: 1400px) {
        padding: 0px 100px;
    }

    @media ${device.mobile} {
        padding: 0px;
    }
`

export const HeaderTitle = styled.div`
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    margin-top: 5px;
    flex: 1;
    align-items: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    font-family: ${branding.font1};

    @media (max-width: 1600px) {
        font-size: 14px;
        padding: 5px 0px;
    }

    @media ${device.mobile} {
        font-size: 16px;
        padding: 5px 0px;
    }

    &.forceMobileDesign {
        font-size: 16px;
    }

    &.product {
        margin-top: 0px !important;
    }
`

export const FormattedDateExtensionDiv = styled.div`
    border: 1px solid ${branding.mainInfoColor};
    border-radius: 5px;
    display: inline-block;
    padding: 5px;
    margin-left: 10px;
    margin-bottom: 10px;
    white-space: nowrap;
`

export const FormattedDatePar = styled.p`
    display: inline;
    font-size: 16px;
    font-style: normal;

    @media (max-width: 1600px) {
        font-size: 14px;
    }
`

export const ScrollContainerRoot = styled.div`
    z-index: 0;
    & .ScrollbarsCustom-Scroller {
        margin-right: -30px !important;
    }

    & .ScrollbarsCustom-Content {
        padding: 0 !important;
        background-color: ${branding.contentBgColorForEachPage ?? "#fff"};
    }

    & .ScrollbarsCustom {
        z-index: 0;
    }

    &::before {
        background-color: rgba(0, 0, 0, 0.4);
    }
`

/* #region  Company info and description section */

export const aspectRatio = "25%" //4:1
export const aspectRatioShrinked = "220px"
interface DetailsRootProps {
    paddingBottom: string
    windowSize: number
    isNetworkingOpen: boolean
    mode?: TopBarMode
}

export const DetailsRoot = styled.div<DetailsRootProps>`
    height: 100%;
    ${(props) => {
        if (props.mode === TopBarMode.ABOVE_PAGE)
            return `

            .networkingCollapsed  {
                background-color: transparent;
                color: white;
                border: none;
                position: relative;
            } 

            .active:first-child  {
                color: ${branding.secondaryTextColor ?? "white"} !important;
                background-color: transparent;
                border: none;
                position: relative;
            } 

            .active:first-child:after {
                content: " ";
                border-bottom: 1px solid white;
                width: ${
                    props.paddingBottom !== aspectRatio ||
                    (!props.isNetworkingOpen && props.windowSize < 1050) ||
                    (props.isNetworkingOpen && props.windowSize < 1300 && props.windowSize > 1100)
                        ? "98%"
                        : "0"
                };
                display: block;
                position: absolute;
                top: 95%;
                left: 1%;
                transition: width linear .5s;
            }

            .networkingCollapsed:after {
                content: " " ;
                border-bottom: 1px solid white;
                width: ${
                    props.paddingBottom !== aspectRatio ||
                    (!props.isNetworkingOpen && props.windowSize < 1050) ||
                    (props.isNetworkingOpen && props.windowSize < 1300 && props.windowSize > 1100)
                        ? "98%"
                        : "0"
                };
                display: block;
                position: absolute;
                top: 95%;
                left: 1%;
                transition: width linear .5s;
            }
            `
    }}

    .MuiTypography-body1 a {
        color: #fff;
    }

    .user-banner-root {
        z-index: 1;
    }

    @media (max-width: 1400px) {
        /* height:${(props) => (props.paddingBottom !== aspectRatio ? "180px" : aspectRatioShrinked)};   */
    }
`
/* #endregion */

/* #region  Date Picker */
export const DatePickerRootTemplate = styled.div`
    & .react-datepicker__tab-loop {
        z-index: 111;
    }

    & .react-datepicker-popper {
        z-index: 10;
        -moz-user-select: none;
        -khtml-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    & .react-datepicker__header {
        border-bottom: none;
        padding-top: 0;
    }

    & .react-datepicker__month {
        margin: 0;
    }

    & .react-datepicker__current-month {
        color: #727272;
        margin-bottom: 10px;
        font-size: 16px;
    }

    & .react-datepicker__day-name {
        font-size: 14px;
        color: #727272;
    }

    & .react-datepicker__month-container {
        padding: 10px;
        font-family: ${branding.font1};
    }

    & .react-datepicker__day {
        font-size: 14px;
        font-weight: 400;
    }

    & .react-datepicker__day--disabled {
        color: #c9c9c9;
    }

    & .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
        outline: none;
    }

    & .react-datepicker__navigation--previous {
        border-right-color: #000;
    }

    & .react-datepicker__navigation--next {
        border-left-color: #000;
    }

    & .react-datepicker__day--selected,
    .react-datepicker__day--selected:hover {
        outline: none;
    }

    & .react-datepicker__day-names {
        font-weight: bold;
    }

    & .react-datepicker__week {
        cursor: default;
    }

    & .react-datepicker__header {
        background-color: #fff;
    }

    & .react-datepicker__day--selected {
        background-color: ${branding.crsTabs.tabItemDefaultActiveStateColor};
        font-weight: 700;
        line-height: 25px;
    }

    & .react-datepicker__navigation-icon--previous {
        display: none !important;
    }

    & .react-datepicker__navigation-icon--next {
        display: none !important;
    }

    & .react-datepicker__navigation--previous {
        cursor: pointer;
        width: 20px;
        height: 20px;
        mask-image: url("/branding/applicationMedia/icons/chevron_left_date_picker.svg");
        background-color: ${branding.crsTabs.tabItemDefaultActiveStateColor};
        margin-top: 10px;
        margin-left: 12px;
        transform: scale(0.9);
    }

    & .react-datepicker__navigation--next {
        cursor: pointer;
        width: 20px;
        height: 20px;
        mask-image: url("/branding/applicationMedia/icons/chevron_right_date_picker.svg");
        background-color: ${branding.crsTabs.tabItemDefaultActiveStateColor};
        margin-top: 10px;
        margin-right: 12px;
        transform: scale(0.9);
    }
`
/* #endregion */
