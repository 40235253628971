import { darkTheme } from "amazon-chime-sdk-component-library-react"
import { DeviceType } from "amazon-chime-sdk-component-library-react/lib/types"
import { useVideoContext } from "../../context/VideoContext"
import { useDevicesV2 } from "../../hooks/useDevices"
import { DeviceSelect } from "../Settings/Settings.styled"

function CameraSelection() {
    const devices = useDevicesV2()
    const videoContext = useVideoContext()
    return (
        <DeviceSelect
            isMulti={false}
            isSearchable={false}
            isClearable={false}
            theme={darkTheme}
            options={devices.videoInputDevices()}
            placeholder={devices.currentVideoInputDevice()?.label}
            value={devices.currentVideoInputDevice()?.deviceId}
            onChange={async (device: DeviceType) => {
                devices.setVideoInputDevice(device.deviceId, device.label)
                videoContext.updateVideoInput()
            }}
        />
    )
}

export default CameraSelection
