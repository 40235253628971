import { format } from "date-fns"
import moment from "moment"
import { useEffect, useState, useCallback } from "react"
import * as React from "react"
import {
    AccessProvider,
    Category,
    Company,
    EventDate,
    EventDateConnectedOrganization,
    ShareTargetType
} from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useAppState } from "../../../globalStates/AppState"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { useLoggedInState, User } from "../../../globalStates/LoggedInUser"
import { useUserRestrictedAreaAccess } from "../../../globalStates/UserRestrictedAreaAccess"
import BookmarkWithToggle from "../../../ui/BookmarkWithToggle"
import { CalendarEntryModalViewMode } from "../../../ui/modals/CalendarEntryModal"
import CalendarEntryModal2 from "../../../ui/modals/calendarEntry/CalendarEntryModal2"
import ContactModal from "../../../ui/modals/ContactModal"
import CrsPersons from "../../../ui/CrsPersons"
import {
    IconCalendarEntry,
    IconLock,
    IconMasterclassTag,
    IconPrerecordedTag,
    IconRemoteProviderTag,
    IconRoundtableTag,
    IconTicket,
    IconTypeformTag,
    IconVideoOnDemandTag,
    IconLiveStageTag,
    IconShare,
    IconThreeDotsMenu,
    IconLocation,
    IconArrowUpLangSwitcher
} from "../../../ui/Icons"
import { hasAccessToOrganization } from "../../../utils/UserUtils"
import { activateChannelBefore, isEventDateLive, momentWithoutTimezoneFromTimezonedMoment } from "../../../utils/DateUtils"
import { buildDetailLink, DetailNavLink, DetailNavLinkType } from "../../detailPages/DetailNavLink"
import { ScheduleHelper } from "../ScheduleHelper"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import BadgeArea from "../../../ui/BadgeArea"
import { CustomOverlayTrigger } from "../../../ui/CustomOverlayTrigger"
import { Tooltip } from "react-bootstrap"
import { loadExhibitorData } from "../../../backendServices/ExhibitorServices"
import { UserOrganizationVisitSource } from "../../../backendServices/TrackingServices"
import { useRecommendModal } from "../../../ui/modals/RecommendModal"
import DeleteCalendarEntryModal from "../../../ui/modals/DeleteCalendarEntryModal"
import { CalendarEntryParticipationStatus } from "../../../API"
import { CalendarEntry, updateCalendarEntryParticipationStatus } from "../../../backendServices/GraphQLServices"
import { useFavoriteState } from "../../../globalStates/Favorites"
import {
    TagWrapper,
    TagIcon,
    TagsWrapper,
    PrivateEventMarkerRoot,
    EventDateBackgroundFixer,
    EventDateEntryRoot,
    EventDateEntryName,
    EventDateFavBookmarkRoot,
    EventDateTileHeaderWrapper,
    TimeAndTagsWrapper,
    EventDateEntryTime,
    EventLogoBadgeContainer,
    EventDateEntryLogoDiv,
    EventDateBadgeWrapper,
    EventDateBadgeDot,
    DescriptionRoot,
    PersonColumn,
    PointsBadge,
    StyledDetailNavlink,
    MobileEventDateEntryRoot,
    MobileEventDateEntryTime,
    MobileEventDateEntryTitle,
    MobileEventDateEntryDescription,
    EventDateTileRoot,
    TileTitleDiv,
    RoundTableAccess,
    MeetingDescriptionDiv,
    EventDateBadgeWrapper1,
    EventDateBadgeDot1,
    LocationName,
    MyScheduleEntryFooter,
    SliderParent,
    SliderItem,
    DateTimeLocationDiv,
    EventDateImageContainer,
    DateBox,
    Day,
    Month,
    EventDateImage,
    ListEventDateEntryDataContainer,
    EventDateFavBookmarkRoot1,
    SpeakersDiv,
    NoResultsContent,
    EventDateConnectedOrganizationsWrapper,
    EventDateConnectedOrganizationLogo,
    ToggleOrgasListButton
} from "./EventDateEntry.styled"
import { trackSelectContent } from "../../../utils/GTMTracking"

interface TileTagProps {
    icon: any
    tooltipText: string
    hasLogo?: boolean
    list?: boolean
    mySchedule?: boolean
    orgaDetailPage?: boolean
}

const TileTag = (props: TileTagProps) => {
    return (
        <CustomOverlayTrigger
            placement="top"
            overlay={
                <Tooltip id="tile-tag-tooltip" style={{ fontFamily: branding.font1, zIndex: 1100 }}>
                    {props.tooltipText}
                </Tooltip>
            }
        >
            <TagWrapper
                hasLogo={props.hasLogo}
                className={props.mySchedule ? "mySchedule" : props.list ? "list" : ""}
                orgaDetailPage={props.orgaDetailPage}
            >
                <TagIcon className={props.mySchedule ? "mySchedule" : ""}>{props.icon}</TagIcon>
            </TagWrapper>
        </CustomOverlayTrigger>
    )
}

interface TileTagsWrapperProps {
    videoOnDemandUrl: string
    typeformUrl: string
    eventDateType: string
    hasLogo?: boolean
    list?: boolean
    mySchedule?: boolean
    orgaDetailPage?: boolean
    eventDateAccessProvider?: AccessProvider
}

export const TileTagsWrapper = (props: TileTagsWrapperProps) => {
    return (
        <TagsWrapper className={props.mySchedule ? "mySchedule" : ""}>
            {props.orgaDetailPage && props.eventDateAccessProvider === AccessProvider.TICKET && (
                <TileTag
                    icon={IconTicket({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                    tooltipText={"Ticket"}
                    hasLogo={props.hasLogo}
                    list={props.list}
                    mySchedule={props.mySchedule}
                    orgaDetailPage={props.orgaDetailPage}
                />
            )}

            {props.orgaDetailPage && props.eventDateAccessProvider === AccessProvider.BACKOFFICE && (
                <TileTag
                    icon={IconLock({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                    tooltipText={"Private Event"}
                    hasLogo={props.hasLogo}
                    list={props.list}
                    mySchedule={props.mySchedule}
                    orgaDetailPage={props.orgaDetailPage}
                />
            )}

            {props.eventDateType === "onsite" && props.videoOnDemandUrl === null && (
                <TileTag
                    icon={IconLiveStageTag({ fill: branding.sideIconBar.sideIconColorDark })}
                    tooltipText={branding.programSchedule.liveStageTagText}
                    hasLogo={props.hasLogo}
                    list={props.list}
                    mySchedule={props.mySchedule}
                    orgaDetailPage={props.orgaDetailPage}
                />
            )}

            {props.eventDateType === "prerecorded" && props.videoOnDemandUrl === null && (
                <TileTag
                    icon={IconPrerecordedTag({
                        fill: branding.sideIconBar.sideIconColorDark
                    })}
                    tooltipText={branding.programSchedule.prerecordedTagText}
                    hasLogo={props.hasLogo}
                    list={props.list}
                    mySchedule={props.mySchedule}
                    orgaDetailPage={props.orgaDetailPage}
                />
            )}

            {props.eventDateType === "masterclass" && props.videoOnDemandUrl === null && (
                <TileTag
                    icon={IconMasterclassTag({
                        fill: branding.sideIconBar.sideIconColorDark
                    })}
                    tooltipText={branding.programSchedule.masterclassTagText}
                    hasLogo={props.hasLogo}
                    list={props.list}
                    mySchedule={props.mySchedule}
                    orgaDetailPage={props.orgaDetailPage}
                />
            )}

            {props.eventDateType === "remoteprovider" && props.videoOnDemandUrl === null && (
                <TileTag
                    icon={IconRemoteProviderTag({
                        fill: branding.sideIconBar.sideIconColorDark
                    })}
                    tooltipText={branding.programSchedule.remoteProviderTagText}
                    hasLogo={props.hasLogo}
                    list={props.list}
                    mySchedule={props.mySchedule}
                    orgaDetailPage={props.orgaDetailPage}
                />
            )}

            {props.eventDateType === "roundtable" && props.videoOnDemandUrl === null && (
                <TileTag
                    icon={IconRoundtableTag({ fill: branding.sideIconBar.sideIconColorDark })}
                    tooltipText={branding.programSchedule.roundtableTagText}
                    hasLogo={props.hasLogo}
                    list={props.list}
                    mySchedule={props.mySchedule}
                    orgaDetailPage={props.orgaDetailPage}
                />
            )}

            {props.videoOnDemandUrl && (
                <TileTag
                    icon={IconVideoOnDemandTag({
                        fill: branding.sideIconBar.sideIconColorDark
                    })}
                    tooltipText={branding.programSchedule.videoOnDemandTagText}
                    hasLogo={props.hasLogo}
                    list={props.list}
                    mySchedule={props.mySchedule}
                    orgaDetailPage={props.orgaDetailPage}
                />
            )}

            {((branding.programSchedule.videoOnDemandNeededToDisplayTypeformTag && props.videoOnDemandUrl) ||
                !branding.programSchedule.videoOnDemandNeededToDisplayTypeformTag) &&
                props.typeformUrl && (
                    <TileTag
                        icon={IconTypeformTag({
                            fill: branding.sideIconBar.sideIconColorDark
                        })}
                        tooltipText={branding.programSchedule.typeformTagText}
                        hasLogo={props.hasLogo}
                        list={props.list}
                        mySchedule={props.mySchedule}
                        orgaDetailPage={props.orgaDetailPage}
                    />
                )}
        </TagsWrapper>
    )
}

interface PrivateEventMarkerProps {
    layout: "tile-layout" | "list-layout" | "tile-layout-absolute"
    accessProvider: AccessProvider | undefined
    height?: number
    bottomPadding?: string
    alwaysSticky?: boolean
}
const PrivateEventMarker: React.FC<PrivateEventMarkerProps> = (props) => {
    const iconSize = "14"
    const displayTicketIcon = props.accessProvider === AccessProvider.TICKET

    return (
        <PrivateEventMarkerRoot
            className={props.layout}
            bottomPadding={props.bottomPadding}
            parentHeight={props.height}
            alwaysSticky={props.alwaysSticky}
        >
            {displayTicketIcon ? (
                <IconTicket width={iconSize} height={iconSize} />
            ) : (
                <IconLock width={iconSize} height={iconSize} />
            )}
            <div className="private-event-marker-text">
                <p>{branding.programSchedule.privateEventMarkerText}</p>
            </div>
        </PrivateEventMarkerRoot>
    )
}

interface EventDateEntryComponentProps {
    eventDate: EventDate
    loggedInUser: User | undefined
    showContactModal: boolean
    selectedSpeaker: string
    width?: string
    height: number
    marginTop: number
    marginLeft?: number
    border?: string
    background?: string
    upcoming: boolean
    pointBadgeData?: Category
    source?: UserOrganizationVisitSource
    setShowContactModal: (value: boolean) => void
    setSelectedSpeaker: (value: string) => void
}

const EventDateEntryComponent = (props: EventDateEntryComponentProps) => {
    const wrapperElement = React.createRef<HTMLDivElement>()
    const nameElement = React.createRef<HTMLDivElement>()
    const speakerElement = React.createRef<HTMLDivElement>()
    const teaserElement = React.createRef<HTMLDivElement>()
    const placeholderElement = React.createRef<HTMLDivElement>()
    const logoElement = React.createRef<HTMLDivElement>()
    const liveElement = React.createRef<HTMLDivElement>()
    const coverElement = React.createRef<HTMLDivElement>()
    const languageState = useLanguageState()
    const lang = languageState.getLanguage()
    const IsEventLive = isEventDateLive(props.eventDate)
    const activateChannel = activateChannelBefore(props.eventDate)
    const isEventUnlocked = useUserRestrictedAreaAccess().isUnlocked(props.eventDate)
    const [extraHeight, setExtraHeight] = useState(0)

    let targetType: DetailNavLinkType
    let targetId: string
    if (
        activateChannel &&
        isEventUnlocked &&
        props.eventDate.channel &&
        props.loggedInUser &&
        (props.loggedInUser.type !== "guest" ||
            (props.eventDate.organizationId && hasAccessToOrganization(props.loggedInUser, props.eventDate.organizationId)))
    ) {
        targetType = "channel"
        targetId = props.eventDate.channel?.id
    } else {
        targetType = "eventdate"
        targetId = props.eventDate.id
    }

    const hourFormat = lang === "en" ? "hh:mm A" : "HH:mm"

    const start =
        props.eventDate.date && props.eventDate.start
            ? moment(props.eventDate.date + "T" + props.eventDate.start).format(hourFormat)
            : "Day start"

    const end =
        props.eventDate.end && props.eventDate.end
            ? moment(props.eventDate.date + "T" + props.eventDate.end).format(hourFormat)
            : "Day end"

    const eventDateBorderColorByOrganization = branding.programSchedule.customColorsForOrganizations.find(
        (organization: any) => props.eventDate?.organizationId! === organization.organizationId
    )?.borderColor

    const isLogoAvailable = () => {
        return props.eventDate.organizationLogoUrl
    }

    function getTileLayoutVersion(): "tile-layout" | "tile-layout-absolute" {
        if (
            (props.eventDate.persons?.length > 0 && props.height < 280) ||
            (props.eventDate.persons?.length > 0 && props.height <= 300 && branding.programSchedule.eventdateDescriptionVisible)
        ) {
            return "tile-layout"
        } else if (props.eventDate.persons?.length > 0 || props.height >= 280) {
            return "tile-layout-absolute"
        } else {
            return "tile-layout"
        }
    }

    useEffect(() => {
        let timer = setTimeout(() => {
            let hig = wrapperElement.current?.clientHeight || 0
            let xtraMargin = hig - props.height + 2 // 2 is border 1 + 1
            setExtraHeight(xtraMargin)
        }, 351)
        return () => {
            clearTimeout(timer)
        }
    }, [wrapperElement, props.height])

    useEffect(() => {
        changeEntryContentVisibility(
            wrapperElement.current!,
            nameElement.current!,
            speakerElement.current!,
            teaserElement.current!,
            placeholderElement.current!,
            logoElement.current!,
            liveElement.current!,
            coverElement.current!
        )
    }, [wrapperElement, nameElement, speakerElement, teaserElement, placeholderElement, logoElement, liveElement, coverElement])

    return (
        <div className="eventDate-entry-root" style={{ padding: "2px 0" }}>
            {props.showContactModal && (
                <ContactModal
                    userId={""}
                    personId={props.selectedSpeaker}
                    close={() => props.setShowContactModal(false)}
                ></ContactModal>
            )}
            <DetailNavLink
                name={props.eventDate.name}
                id={targetId}
                type={targetType}
                width={props.width ? props.width : ""}
                source={props.source}
                onClick={() => {
                    trackSelectContent(props.eventDate.name, "Details", "Eventdate")
                }}
            >
                <EventDateBackgroundFixer>
                    <EventDateEntryRoot
                        className="eventDateRoot"
                        ref={wrapperElement}
                        height={props.height}
                        marginTop={props.marginTop}
                        isOpen={false}
                        marginBottom={extraHeight}
                        marginLeft={props.marginLeft ? props.marginLeft : 0}
                        background={props.background ?? "#fff"}
                        border={eventDateBorderColorByOrganization ? eventDateBorderColorByOrganization : props.border ?? "#000"}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <EventDateEntryName ref={nameElement} style={{ marginTop: 6 }}>
                                {props.eventDate.name}
                            </EventDateEntryName>
                            <EventDateFavBookmarkRoot>
                                <BookmarkWithToggle
                                    newBookmarkItem={true}
                                    fontSize={"15px"}
                                    color={branding.sideIconBar.sideIconColorDark}
                                    stroke={branding.sideIconBar.sideIconColorDark}
                                    favIconBasic={true}
                                    type={"eventdate"}
                                    id={props.eventDate.id}
                                    name={props.eventDate.name}
                                />
                            </EventDateFavBookmarkRoot>
                        </div>

                        <EventDateTileHeaderWrapper>
                            <TimeAndTagsWrapper>
                                <EventDateEntryTime
                                    upcoming={props.upcoming}
                                    color={IsEventLive ? "black" : branding.mainInfoColor}
                                    hasLogo={isLogoAvailable() ? "0px" : "10px"}
                                >
                                    {props.upcoming && props.eventDate
                                        ? format(
                                              moment(props.eventDate.date).toDate(),
                                              branding.eventTiming.eventDaysFormatPatternShort,
                                              {
                                                  locale: lang === "de" ? de : en
                                              }
                                          ) + ","
                                        : ""}{" "}
                                    {start} - {end + (lang === "de" ? " Uhr" : "")}
                                </EventDateEntryTime>

                                {branding.programSchedule.showTileTags && (
                                    <TileTagsWrapper
                                        videoOnDemandUrl={props.eventDate.videoOnDemandUrl}
                                        typeformUrl={props.eventDate.typeformUrl}
                                        eventDateType={props.eventDate.type || ""}
                                        hasLogo={isLogoAvailable() !== undefined}
                                    />
                                )}
                            </TimeAndTagsWrapper>
                        </EventDateTileHeaderWrapper>

                        {branding.programSchedule.showConnectedOrganizations && (
                            <ConnectedOrganizations
                                eventDate={props.eventDate}
                                organizations={[
                                    {
                                        logoUrl: props.eventDate?.organizationLogoUrl,
                                        id: props.eventDate?.organizationId,
                                        name:
                                            props.eventDate?.organizer ||
                                            props.eventDate?.organizationName ||
                                            branding.programSchedule.mainOrganizerPlaceholderLabel,
                                        consent: props.eventDate.organizationConsent
                                    } as EventDateConnectedOrganization
                                ].concat(props.eventDate.organizations?.length ? props.eventDate.organizations : [])}
                            />
                        )}

                        {(IsEventLive || isLogoAvailable()) && (
                            <EventLogoBadgeContainer>
                                {isLogoAvailable() && !branding.programSchedule.showConnectedOrganizations && (
                                    <EventDateEntryLogoDiv style={{ display: isLogoAvailable() ? "" : "none" }}>
                                        <img
                                            src={
                                                props.eventDate.organizationLogoUrl && props.eventDate.organizationLogoUrl !== ""
                                                    ? props.eventDate.organizationLogoUrl
                                                    : "/branding/applicationMedia/rsz_defaultgrey.png"
                                            }
                                            alt={props.eventDate.logoText}
                                            width="30"
                                            height="30"
                                            style={{ objectFit: "contain" }}
                                        />
                                    </EventDateEntryLogoDiv>
                                )}
                                {IsEventLive && (
                                    <EventDateBadgeWrapper
                                        ref={liveElement}
                                        style={{
                                            gridColumn: isLogoAvailable() ? 2 : 1,
                                            justifySelf: isLogoAvailable() ? "right" : "left",
                                            display: IsEventLive ? "" : "none"
                                        }}
                                    >
                                        <EventDateBadgeDot />
                                        {branding.personDetailPageContent.liveSessionsTitle}
                                    </EventDateBadgeWrapper>
                                )}
                            </EventLogoBadgeContainer>
                        )}

                        <BadgeArea
                            categories={props.eventDate.categories || []}
                            marginTop="10px"
                            marginBottom={"5px"}
                            maxBadgeCount={branding.programPageContent.liveSessionsMaxBadgeCount}
                        />

                        {branding.programSchedule.eventdateDescriptionVisible && (
                            <DescriptionRoot className="mt-3">{props.eventDate.teaser}</DescriptionRoot>
                        )}

                        <PersonColumn ref={speakerElement}>
                            <CrsPersons
                                layoutType={1}
                                showModalPopup={true}
                                justifycontent="flex-start"
                                persons={[...props.eventDate.persons]}
                                onUserClick={(userId: string) => {
                                    props.setShowContactModal(true)
                                    props.setSelectedSpeaker(userId)
                                }}
                                personsToShow={3}
                                eventDateEntryContent={true}
                                width="30px"
                                minWidth="30px"
                                height="30px"
                                color="#575757"
                            />

                            {props.pointBadgeData &&
                                props.eventDate.categories
                                    ?.map((x) => x.alias)
                                    .includes(branding.programSchedule.pointsBadgeCategoryAlias) && (
                                    <PointsBadge
                                        textColor={props.pointBadgeData.colorText}
                                        fillColor={props.pointBadgeData.colorFill}
                                        borderColor={props.pointBadgeData.colorBorder}
                                    >
                                        {branding.programSchedule.pointsBadgeText}
                                    </PointsBadge>
                                )}
                        </PersonColumn>

                        {props.eventDate.accessProvider !== AccessProvider.PUBLIC && props.eventDate.persons?.length === 0 && (
                            <div style={{ height: "50px" }}></div>
                        )}
                        <div style={{ marginBottom: "70px" }}></div>

                        {props.eventDate.accessProvider !== AccessProvider.PUBLIC && (
                            <PrivateEventMarker
                                layout={getTileLayoutVersion()}
                                accessProvider={props.eventDate.accessProvider}
                                height={props.height}
                            />
                        )}
                    </EventDateEntryRoot>
                </EventDateBackgroundFixer>
            </DetailNavLink>
        </div>
    )
}

/* #region  My schedule part */

interface MyScheduleEntryProps {
    myScheduleObject: any
    marginLeft: number
    marginTop: number
    height: number
    overlappingObjects?: any[] | null
    isAdded?: boolean
    upcoming?: boolean
    isMyFairPage?: boolean
    zIndex: number
    pointsBadgeData?: Category

    isMobile?: boolean
    calendarModalClose?: () => void
    sliderWrapper: boolean
}

const MyScheduleEventDateEntry: React.FunctionComponent<MyScheduleEntryProps> = (props) => {
    const languageState = useLanguageState()
    const language = languageState.getLanguage()
    const userAccessState = useUserRestrictedAreaAccess()
    const loggedInUserId = useLoggedInState().user()?.profileId
    const organizationId = props.myScheduleObject.hasOwnProperty("organizationId") ? props.myScheduleObject.organizationId : null
    const isPrivate =
        (props.myScheduleObject.accessProvider && props.myScheduleObject.accessProvider !== AccessProvider.PUBLIC) || false
    const isEventUnlocked = userAccessState.isUnlocked(props.myScheduleObject)
    const [organization, setOrganization] = useState<Company | null>(null)
    const timezone = useAppState().timezone
    const [viewMode, setViewMode] = useState<CalendarEntryModalViewMode | undefined>(CalendarEntryModalViewMode.VIEW)
    const [showCalendarEntry, setShowCalendarEntry] = useState<boolean>(false)

    const [showDeclineModal, setShowDeclineModal] = useState<boolean>(false)
    //eslint-disable-next-line
    const [showMeetingPopup, setShowMeetingPopup] = useState<boolean>(false)

    function checkEventTime(): "past" | "current" | "future" {
        //current dateTime
        const date = momentWithoutTimezoneFromTimezonedMoment(moment(), timezone).toDate()
        const currentDate = moment(date).format("YYYY-MM-DD")
        const currentTime = moment(date).format("HH:mm")
        const [currentHours, currentMinutes] = currentTime.split(":")
        const currentTimeStamp = parseInt(currentHours) * 60 + parseInt(currentMinutes)

        //event-meeting dateTime
        const eventDate = props.myScheduleObject.hasOwnProperty("title")
            ? moment(props.myScheduleObject.end).format("YYYY-MM-DD")
            : props.myScheduleObject.date

        const meetingStartTime = moment(new Date(props.myScheduleObject.start)).format("HH:mm")
        const meetingEndTime = moment(new Date(props.myScheduleObject.end)).format("HH:mm")

        const eventStartTime = props.myScheduleObject.hasOwnProperty("title") ? meetingStartTime : props.myScheduleObject.start
        const eventEndTime = props.myScheduleObject.hasOwnProperty("title") ? meetingEndTime : props.myScheduleObject.end
        const [eventStartHours, eventStartMinutes] = eventStartTime.split(":")
        const [eventEndHours, eventEndMinutes] = eventEndTime.split(":")

        const eventStartTimeStamp = parseInt(eventStartHours) * 60 + parseInt(eventStartMinutes)
        const eventEndTimeStamp = parseInt(eventEndHours) * 60 + parseInt(eventEndMinutes)

        if (eventDate < currentDate || (eventDate <= currentDate && eventEndTimeStamp < currentTimeStamp)) return "past"
        else if (eventDate > currentDate || (eventDate >= currentDate && eventStartTimeStamp > currentTimeStamp)) {
            return "future"
        }

        return "current"
    }

    useEffect(() => {
        if (organizationId) {
            loadExhibitorData({
                organizationid: organizationId,
                hideNewsdata: false,
                showPersonsEventDates: true
            }).then((data) => {
                setOrganization(data.content)
            })
        }
    }, [organizationId])

    async function declineMeeting() {
        if (loggedInUserId != null) {
            const myParticipation = props.myScheduleObject?.participants?.items.find(
                (item: any) => item.userId === loggedInUserId
            )
            if (myParticipation) {
                const result = await updateCalendarEntryParticipationStatus(
                    myParticipation.id,
                    loggedInUserId,
                    undefined,
                    CalendarEntryParticipationStatus.DECLINED
                )

                if (result != null) {
                    setShowDeclineModal(false)
                    setShowMeetingPopup(false)
                    if (props.calendarModalClose) props.calendarModalClose()
                    return
                }
            }
        }
    }

    function getFormattedTime(type: string) {
        let date = new Date()

        if (props.myScheduleObject.hasOwnProperty("startHour")) {
            if (type === "start") {
                date.setHours(props.myScheduleObject.startHour)
                date.setMinutes(props.myScheduleObject.startMinutes)
            } else {
                date.setHours(props.myScheduleObject.endHour)
                date.setMinutes(props.myScheduleObject.endMinutes)
            }
        } else {
            if (type === "start")
                date = momentWithoutTimezoneFromTimezonedMoment(moment(new Date(props.myScheduleObject.start)), timezone).toDate()
            else date = momentWithoutTimezoneFromTimezonedMoment(moment(new Date(props.myScheduleObject.end)), timezone).toDate()
        }
        return language === "en" ? moment(date).format("hh:mm a").toUpperCase() : moment(date).format("HH:mm")
    }
    const eventTime = checkEventTime()
    const activateChannel = activateChannelBefore(props.myScheduleObject)
    const isRoundtable = props.myScheduleObject.roundTableAccessStatus && props.myScheduleObject.roundTableAccessStatus.length > 0
    const roundtableAccessGranted = props.myScheduleObject.roundTableAccessStatus === "GRANTED"

    const acceptedParticipants = () => {
        return props.myScheduleObject.participants.items
            .filter((item: any) => item.status === "ACCEPTED" && item.user.id !== loggedInUserId)
            .map((it: any) => " " + it.user.name)
    }

    const meetingDescription = (
        <>
            {props.myScheduleObject.hasOwnProperty("participants") && (
                <>
                    {organization
                        ? branding.programSchedule.meetingText +
                          " " +
                          organization.name +
                          `${acceptedParticipants.length > 0 ? " ( " + acceptedParticipants + ")" : ""}`
                        : branding.programSchedule.meetingText +
                          props.myScheduleObject.participants.items
                              .filter((item: any) => item.user.id !== loggedInUserId)
                              .map((item: any) => " " + item.user.name)}
                </>
            )}
        </>
    )

    // eslint-disable-next-line
    function getViewMode() {
        if (props.myScheduleObject.hasOwnProperty("title")) {
            return props.myScheduleObject.userId === loggedInUserId &&
                moment(props.myScheduleObject.start).toDate().getFullYear() === moment().year() &&
                moment(moment(props.myScheduleObject.start).toDate()).dayOfYear() >= moment().dayOfYear()
                ? CalendarEntryModalViewMode.EDIT
                : CalendarEntryModalViewMode.VIEW
        } else {
            return CalendarEntryModalViewMode.VIEW
        }
    }

    return (
        <div>
            {showCalendarEntry && (
                <CalendarEntryModal2
                    close={() => {
                        setShowCalendarEntry(false)
                        props.calendarModalClose?.()
                    }}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    calendarEntry={props.myScheduleObject}
                    sotUser={[]}
                />
            )}
            <DeleteCalendarEntryModal
                backdrop="static"
                type="decline"
                animation={false}
                show={showDeclineModal}
                deleteCalendarEntry={() => declineMeeting()}
                setShowConfirmationDialog={() => setShowDeclineModal(false)}
            />
            <StyledDetailNavlink
                name={props.myScheduleObject.hasOwnProperty("title") ? props.myScheduleObject.title : props.myScheduleObject.name}
                id={
                    activateChannel && isEventUnlocked && props.myScheduleObject.channel
                        ? props.myScheduleObject.channel.id
                        : props.myScheduleObject.id
                }
                type={activateChannel && isEventUnlocked && props.myScheduleObject.channel ? "channel" : "eventdate"}
                onClick={(e) => {
                    if (props.myScheduleObject.hasOwnProperty("title") && !showDeclineModal) {
                        e.preventDefault()
                        setViewMode(CalendarEntryModalViewMode.VIEW)
                        setShowCalendarEntry(true)
                    }
                }}
                width={props.isMobile ? "220px" : props.upcoming ? "365px" : "320px"}
            >
                {/* //won't be used in the current version, will be reworked later on
                    <div
                        onClick={(e: any) => {
                            e.preventDefault()
                            e.stopPropagation()
                        }}
                        onMouseEnter={() => {
                            if (props.myScheduleObject.hasOwnProperty("title")) {
                                setShowMeetingPopup(true)
                            }
                        }}
                        onMouseLeave={() => {
                            if (props.myScheduleObject.hasOwnProperty("title")) {
                                setShowMeetingPopup(false)
                            }
                        }}
                    >
                        {showMeetingPopup && (
                            <MyScheduleMeetingModal
                                meeting={props.myScheduleObject}
                                onEditClick={() => {
                                    showCalendarEntryModal({
                                        calendarEntry: props.myScheduleObject,
                                        viewMode: getViewMode(),
                                        sotUser: []
                                    })
                                }}
                                onDeclineClick={() => {
                                    setShowDeclineModal(true)
                                }}
                                marginLeft={props.marginLeft + 320}
                                marginTop={-((418 - props.height) / 2)}
                                timeString={
                                    format(
                                        moment(props.myScheduleObject.start).toDate(),
                                        branding.eventTiming.eventDaysFormatPatternShort,
                                        {
                                            locale: language === "de" ? de : en
                                        }
                                    ) +
                                    ", " +
                                    getFormattedTime("start") +
                                    " - " +
                                    getFormattedTime("end") +
                                    (language === "de" ? " Uhr" : "")
                                }
                                close={() => setShowMeetingPopup(false)}
                            />
                        )}
                    </div>
                    */}
                {props.isMobile ? (
                    <MobileEventDateEntryRoot key={props.myScheduleObject.id}>
                        <div style={{ flexDirection: "column" }}>
                            <MobileEventDateEntryTime>
                                {getFormattedTime("start") + " - " + getFormattedTime("end") + (language === "de" ? " Uhr" : "")}
                            </MobileEventDateEntryTime>

                            <MobileEventDateEntryTitle>
                                {props.myScheduleObject.hasOwnProperty("title")
                                    ? props.myScheduleObject.title
                                    : props.myScheduleObject.name}
                            </MobileEventDateEntryTitle>

                            {props.myScheduleObject.hasOwnProperty("teaser") && (
                                <MobileEventDateEntryDescription>{props.myScheduleObject.teaser}</MobileEventDateEntryDescription>
                            )}
                            {props.myScheduleObject.hasOwnProperty("participants") && (
                                <MobileEventDateEntryDescription>{meetingDescription}</MobileEventDateEntryDescription>
                            )}
                        </div>
                    </MobileEventDateEntryRoot>
                ) : (
                    <EventDateTileRoot
                        height={props.height}
                        marginTop={props.marginTop}
                        marginLeft={props.sliderWrapper ? 0 : props.marginLeft}
                        width={"100%"}
                        zIndex={props.zIndex}
                        className={
                            (isPrivate ? "private" : "open") +
                            " " +
                            (props.isMyFairPage ? "isMyFairPage" : "") +
                            " determineScheduleWidth"
                        }
                        onMouseEnter={() => {
                            if (props.myScheduleObject.hasOwnProperty("title")) {
                                setShowMeetingPopup(true)
                            }
                        }}
                        onMouseLeave={() => {
                            if (props.myScheduleObject.hasOwnProperty("title")) {
                                setShowMeetingPopup(false)
                            }
                        }}
                    >
                        {/* padding bottom 50px(height of the private event marker div) -> to be able to see icons which are aligned with the bottom of the element */}
                        <div
                            style={{ paddingBottom: isPrivate ? "50px" : "0" }}
                            className="h-100 d-flex justify-content-between flex-column opacity mb-20"
                        >
                            <div className="d-flex justify-content-between align-items-start">
                                <div className="w-100">
                                    <TileTitleDiv>
                                        <div
                                            style={{
                                                marginTop: "2px",
                                                fontFamily: branding.font1,
                                                fontSize: "16px",
                                                fontWeight: "bold"
                                            }}
                                        >
                                            {props.myScheduleObject.hasOwnProperty("title")
                                                ? props.myScheduleObject.title
                                                : props.myScheduleObject.name}
                                        </div>
                                    </TileTitleDiv>
                                    {props.isMyFairPage && (
                                        <div
                                            style={{
                                                marginBottom: "10px",
                                                fontFamily: branding.font1,
                                                fontSize: "12px",
                                                lineHeight: "20px"
                                            }}
                                        >
                                            {format(
                                                moment(props.myScheduleObject.start).toDate(),
                                                branding.eventTiming.eventDaysFormatPatternShort,
                                                {
                                                    locale: language === "de" ? de : en
                                                }
                                            ) +
                                                ", " +
                                                getFormattedTime("start") +
                                                " - " +
                                                getFormattedTime("end") +
                                                (language === "de" ? " Uhr" : "")}
                                        </div>
                                    )}
                                    {props.height <= 55 ? null : (
                                        <div>
                                            {isRoundtable && (
                                                <RoundTableAccess accessGranted={roundtableAccessGranted}>
                                                    {roundtableAccessGranted
                                                        ? branding.programSchedule.roundtableAccessGranted
                                                        : branding.programSchedule.roundtableAccessRequested}
                                                </RoundTableAccess>
                                            )}
                                            {props.myScheduleObject.hasOwnProperty("participants") && props.isMyFairPage && (
                                                <MeetingDescriptionDiv className="d-flex justify-content-between w-100">
                                                    {meetingDescription}
                                                </MeetingDescriptionDiv>
                                            )}
                                            {props.pointsBadgeData &&
                                                props.myScheduleObject.categories?.includes(
                                                    branding.programSchedule.pointsBadgeCategoryAlias
                                                ) && (
                                                    <PointsBadge
                                                        textColor={props.pointsBadgeData.colorText}
                                                        fillColor={props.pointsBadgeData.colorFill}
                                                        borderColor={props.pointsBadgeData.colorBorder}
                                                    >
                                                        {branding.programSchedule.pointsBadgeText}
                                                    </PointsBadge>
                                                )}

                                            {!props.isMyFairPage &&
                                                props.myScheduleObject.organizationLogoUrl &&
                                                !branding.mySchedule.showConnectedOrganizations && (
                                                    <EventLogoBadgeContainer style={{ marginBottom: "0px" }}>
                                                        <EventDateEntryLogoDiv style={{ height: "16px", marginTop: "3px" }}>
                                                            <img
                                                                src={
                                                                    props.myScheduleObject.organizationLogoUrl &&
                                                                    props.myScheduleObject.organizationLogoUrl !== ""
                                                                        ? props.myScheduleObject.organizationLogoUrl
                                                                        : "/branding/applicationMedia/rsz_defaultgrey.png"
                                                                }
                                                                alt={props.myScheduleObject.logoText}
                                                                width="30"
                                                                height="30"
                                                                style={{ objectFit: "contain" }}
                                                            />
                                                        </EventDateEntryLogoDiv>
                                                    </EventLogoBadgeContainer>
                                                )}

                                            {!props.isMyFairPage && (
                                                <>
                                                    <div style={{ marginTop: "10px", display: "inline-block" }}>
                                                        {getFormattedTime("start") +
                                                            " - " +
                                                            getFormattedTime("end") +
                                                            (language === "de" ? " Uhr" : "")}
                                                    </div>
                                                    {eventTime === "current" &&
                                                        !props.myScheduleObject.hasOwnProperty("title") && (
                                                            <div
                                                                style={{
                                                                    display: "inline-block",
                                                                    marginTop: "5px",
                                                                    float: "right"
                                                                }}
                                                            >
                                                                <EventDateBadgeWrapper1>
                                                                    <EventDateBadgeDot1 />
                                                                    {branding.receptionPage.nowLive}
                                                                </EventDateBadgeWrapper1>
                                                            </div>
                                                        )}
                                                </>
                                            )}
                                            {props.myScheduleObject.locationName && (
                                                <LocationName>
                                                    {IconLocation({ fill: branding.mainInfoColor })}{" "}
                                                    {props.myScheduleObject.locationName}
                                                </LocationName>
                                            )}

                                            <div style={{ marginTop: "10px" }}></div>

                                            {props.height <= 125 ? null : (
                                                <div style={{ marginTop: "10px" }}>
                                                    {props.myScheduleObject.hasOwnProperty("participants") ? (
                                                        <CrsPersons
                                                            justifycontent="flex-start"
                                                            layoutType={1}
                                                            showModalPopup={false}
                                                            persons={
                                                                props.myScheduleObject.participants?.items?.filter(
                                                                    (item: any) =>
                                                                        item.status === "ACCEPTED" &&
                                                                        item.user.id !== loggedInUserId
                                                                ) || []
                                                            }
                                                            personsToShow={3}
                                                            width="24px"
                                                            height="24px"
                                                            color={branding.crsTabs.defaultActionItemActiveStateColor}
                                                            fontSize="10px"
                                                            marginRight="-5px"
                                                            className="mySchedule"
                                                        ></CrsPersons>
                                                    ) : (
                                                        <>
                                                            {props.myScheduleObject.persons &&
                                                                props.myScheduleObject.persons?.length! > 0 && (
                                                                    <CrsPersons
                                                                        justifycontent="flex-start"
                                                                        layoutType={1}
                                                                        showModalPopup={false}
                                                                        persons={props.myScheduleObject.persons || []}
                                                                        personsToShow={3}
                                                                        width="24px"
                                                                        height="24px"
                                                                        color={branding.crsTabs.defaultActionItemActiveStateColor}
                                                                        fontSize="10px"
                                                                        marginRight="-5px"
                                                                        className="mySchedule"
                                                                    ></CrsPersons>
                                                                )}
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    {branding.mySchedule.showConnectedOrganizations && (
                                        <ConnectedOrganizations
                                            organizations={[
                                                {
                                                    logoUrl: props.myScheduleObject?.organizationLogoUrl,
                                                    id: props.myScheduleObject?.organizationId,
                                                    name:
                                                        props.myScheduleObject?.organizer ||
                                                        props.myScheduleObject?.organizationName ||
                                                        branding.programSchedule.mainOrganizerPlaceholderLabel,
                                                    consent: props.myScheduleObject.organizationConsent
                                                } as EventDateConnectedOrganization
                                            ].concat(
                                                props.myScheduleObject.organizations?.length
                                                    ? props.myScheduleObject.organizations
                                                    : []
                                            )}
                                            eventDate={props.myScheduleObject}
                                        />
                                    )}
                                </div>

                                {props.isMyFairPage ? null : (
                                    <MyScheduleEntryFooter>
                                        {props.myScheduleObject.hasOwnProperty("title") ? (
                                            IconCalendarEntry({
                                                fill: branding.sideIconBar.sideIconColorDark,
                                                width: "15",
                                                height: "15"
                                            })
                                        ) : (
                                            <div style={{ alignItems: "center", display: "flex" }}>
                                                {isPrivate && (
                                                    <CustomOverlayTrigger
                                                        placement="top"
                                                        overlay={
                                                            <Tooltip
                                                                id="private-marker-tooltip"
                                                                style={{ fontFamily: branding.font1 }}
                                                            >
                                                                {branding.programSchedule.privateEventMarkerText}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "inline-block",
                                                                marginRight: "5px",
                                                                marginBottom: "3px"
                                                            }}
                                                        >
                                                            {IconLock({
                                                                fill: branding.sideIconBar.sideIconColorDark,
                                                                width: "15",
                                                                height: "15"
                                                            })}
                                                        </div>
                                                    </CustomOverlayTrigger>
                                                )}
                                                {branding.programSchedule.showTileTags && props.myScheduleObject.type && (
                                                    <TileTagsWrapper
                                                        videoOnDemandUrl={props.myScheduleObject.videoOnDemandUrl}
                                                        typeformUrl={props.myScheduleObject.typeformUrl}
                                                        eventDateType={props.myScheduleObject.type || ""}
                                                        hasLogo={true}
                                                        mySchedule
                                                    />
                                                )}
                                                <CustomOverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id="bookmark-tooltip" style={{ fontFamily: branding.font1 }}>
                                                            {branding.organizationDetailPageContent.unBookmarkPerson}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div style={{ display: "inline-block" }}>
                                                        <BookmarkWithToggle
                                                            newBookmarkItem={true}
                                                            fontSize={"15px"}
                                                            type={"eventdate"}
                                                            id={props.myScheduleObject.id}
                                                            name={props.myScheduleObject.name}
                                                            customSize={props.isMyFairPage ? "20" : "15"}
                                                            color={branding.sideIconBar.sideIconColorDark}
                                                        />
                                                    </div>
                                                </CustomOverlayTrigger>
                                            </div>
                                        )}
                                    </MyScheduleEntryFooter>
                                )}
                            </div>
                        </div>

                        <div style={{ marginBottom: "10px" }}></div>
                    </EventDateTileRoot>
                )}
            </StyledDetailNavlink>
        </div>
    )
}

/* #endregion */

interface EventDateEntryProps {
    eventDate: EventDate
    marginTop: number
    marginLeft?: number
    height: number
    width?: string
    mySchedule?: boolean
    myScheduleObject?: any
    overlappingObjects?: any[] | null
    isAdded?: boolean
    upcoming?: boolean
    isMyFairPage?: boolean
    zIndex?: number
    pointBadgeData?: Category
    background?: string
    border?: string
    customborderbyorganization?: string
    source?: UserOrganizationVisitSource
    isMobile?: boolean
    calendarModalClose?: () => void
}

const EventDateEntry: React.FunctionComponent<EventDateEntryProps> = (props) => {
    const [showContactModal, setShowContactModal] = useState<boolean>(false)
    const [selectedSpeaker, setSelectedSpeaker] = useState<string>("")
    const loggedInUser = useLoggedInState().user()

    /*
     PROBLEM: precalculating the height's is difficult. We do know the available height, line-height etc, but we cannot precalculate the needed amount of rows for the text.
     We could calculate that we have enough room for 4 lines of header, or 10 lines of teaser. But we won't know if the text for them will fill those lines.
    */

    if (props.overlappingObjects) {
        if (props.isAdded) {
            return <div></div>
        } else {
            const maxHeight = props.overlappingObjects.sort((a, b) => b.height - a.height)[0].height
            return (
                <SliderParent
                    height={maxHeight + "px"}
                    marginTop={props.marginTop + "px"}
                    itemsCount={props.overlappingObjects.length}
                    size={props.mySchedule ? 320 : 350}
                >
                    <div className="sliderWrapper" style={{ marginLeft: props.marginLeft }}>
                        {props.overlappingObjects.map((item, index) => {
                            return (
                                <SliderItem key={index} width={props.mySchedule ? 320 : 350} className="determineScheduleWidth">
                                    {props.mySchedule ? (
                                        <MyScheduleEventDateEntry
                                            myScheduleObject={item.object}
                                            height={item.height}
                                            marginTop={0}
                                            marginLeft={props.marginLeft || 0}
                                            zIndex={props.zIndex!}
                                            pointsBadgeData={props.pointBadgeData}
                                            isMobile={props.isMobile}
                                            calendarModalClose={props.calendarModalClose}
                                            sliderWrapper={true}
                                        />
                                    ) : (
                                        <EventDateEntryComponent
                                            eventDate={item.object}
                                            loggedInUser={loggedInUser}
                                            showContactModal={showContactModal}
                                            selectedSpeaker={selectedSpeaker}
                                            width="auto"
                                            height={item.height}
                                            marginTop={0}
                                            marginLeft={props.marginLeft}
                                            border={props.border}
                                            background={props.background}
                                            upcoming={props.upcoming ?? false}
                                            pointBadgeData={props.pointBadgeData}
                                            setShowContactModal={setShowContactModal}
                                            setSelectedSpeaker={setSelectedSpeaker}
                                            source={props.source}
                                        />
                                    )}
                                </SliderItem>
                            )
                        })}
                    </div>
                </SliderParent>
            )
        }
    } else if (props.mySchedule || props.isMobile) {
        return (
            <MyScheduleEventDateEntry
                myScheduleObject={props.myScheduleObject}
                height={props.height}
                marginTop={props.marginTop}
                marginLeft={props.marginLeft || 0}
                upcoming={props.upcoming}
                zIndex={props.zIndex!}
                pointsBadgeData={props.pointBadgeData}
                isMobile={props.isMobile}
                isMyFairPage={props.isMyFairPage}
                calendarModalClose={props.calendarModalClose}
                sliderWrapper={false}
            />
        )
    } else {
        return (
            <EventDateEntryComponent
                eventDate={props.eventDate}
                loggedInUser={loggedInUser}
                showContactModal={showContactModal}
                selectedSpeaker={selectedSpeaker}
                width={props.width}
                height={props.height}
                marginTop={props.marginTop}
                marginLeft={props.marginLeft}
                border={props.border}
                background={props.background}
                upcoming={props.upcoming ?? false}
                pointBadgeData={props.pointBadgeData}
                setShowContactModal={setShowContactModal}
                setSelectedSpeaker={setSelectedSpeaker}
                source={props.source}
            />
        )
    }
}

export default EventDateEntry

interface TimeAndLocationProps {
    item: any
    mySchedule?: boolean
    orgaDetailPage?: boolean
}

const Title: React.FunctionComponent<TimeAndLocationProps> = (props) => {
    return (
        <div
            style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "10px",
                color: branding.programSchedule.listViewTitleColor,
                fontFamily: branding.programSchedule.listViewTitleFont,
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical"
            }}
        >
            {props.item.title ? props.item.title : props.item.name}{" "}
        </div>
    )
}

const DateTimeAndLocation: React.FunctionComponent<TimeAndLocationProps> = (props) => {
    const lang = useLanguageState().getLanguage()
    const item = props.item
    const hourFormat = lang === "en" ? "hh:mm A" : "HH:mm"
    const date = moment((item.date ? item.date + "T" : "") + item.start)
        .locale(lang)
        .format("llll")

    let start = moment((item.date ? item.date + "T" : "") + item.start).format(hourFormat)
    let end = moment((item.date ? item.date + "T" : "") + item.end).format(hourFormat)
    return (
        <div className="d-flex">
            {props.orgaDetailPage ? (
                <DateTimeLocationDiv>
                    {date} | {item.location}
                </DateTimeLocationDiv>
            ) : (
                <div style={{ fontSize: "12px", fontWeight: 400, marginBottom: "10px" }}>
                    {start} - {end + (lang === "de" ? " Uhr" : "")}
                    {item.location
                        ? " - " + item.location
                        : branding.calendarEntryModalPageContent.locationPickerActive && item.locationName
                        ? " - " + item.locationName
                        : ""}
                </div>
            )}

            {item.hasOwnProperty("title") && !props.orgaDetailPage ? (
                <TagsWrapper>
                    <TileTag
                        icon={IconCalendarEntry({
                            fill: branding.sideIconBar.sideIconColorDark,
                            width: "15",
                            height: "15"
                        })}
                        tooltipText={branding.mySchedule.dropdownFilterMeeting}
                        hasLogo
                        list
                        orgaDetailPage={props.orgaDetailPage}
                    />
                </TagsWrapper>
            ) : (
                <>
                    {(branding.programSchedule.showTileTags || props.orgaDetailPage) && (
                        <TileTagsWrapper
                            videoOnDemandUrl={item.videoOnDemandUrl}
                            typeformUrl={item.typeformUrl}
                            eventDateType={item.type || ""}
                            hasLogo={true}
                            list={true}
                            orgaDetailPage={props.orgaDetailPage}
                            eventDateAccessProvider={item.accessProvider}
                        />
                    )}
                </>
            )}
        </div>
    )
}

export function compareEventDatesMeetings(a: any, b: any) {
    var timeA = a.hasOwnProperty("startHour") ? a.start : moment(new Date(a.start)).format("HH:mm")
    var timeB = b.hasOwnProperty("startHour") ? b.start : moment(new Date(b.start)).format("HH:mm")

    const [aHours, aMinutes] = timeA.split(":")
    const aTimeStamp = parseInt(aHours) * 60 + parseInt(aMinutes)

    const [bHours, bMinutes] = timeB.split(":")
    const bTimeStamp = parseInt(bHours) * 60 + parseInt(bMinutes)

    if (aTimeStamp > bTimeStamp) return 1
    if (bTimeStamp > aTimeStamp) return -1

    return 0
}

interface ListEntryColumnProps {
    eventDates: EventDate[]
    meetings?: any[]
    helper?: ScheduleHelper
    pointBadgeData?: Category
    mySchedule?: boolean
    orgaDetailPage?: boolean
    calendarModalClose?: () => void
    openRecommendModalInParent?: (
        shareType: ShareTargetType,
        entityId: string,
        entityName: string,
        link: string,
        onOpenCallback?: () => void
    ) => void
}

export const ListEventDateEntryColumn: React.FunctionComponent<ListEntryColumnProps> = (props) => {
    const userAccessState = useUserRestrictedAreaAccess()
    const favoriteState = useFavoriteState()
    const { RecommendModal, showRecommendModal } = useRecommendModal()
    const [viewMode, setViewMode] = useState<CalendarEntryModalViewMode | undefined>(CalendarEntryModalViewMode.VIEW)
    const [items, setItems] = useState<Array<any>>([])
    const [singleItem, setSingleItem] = useState<CalendarEntry | undefined>()
    const [showCalendarEntry, setShowCalendarEntry] = useState<boolean>(false)

    useEffect(() => {
        let itemsTemp: any[] = []

        props.eventDates.forEach((eventDate) => {
            itemsTemp.push(eventDate)
        })

        if (props.mySchedule && props.meetings) {
            props.meetings?.forEach((meeting) => {
                meeting.dateTimeStart = meeting.start
                meeting.dateTimeEnd = meeting.end
                itemsTemp.push(meeting)
            })
        }

        itemsTemp.sort(compareEventDatesMeetings)

        setItems(itemsTemp)
        // eslint-disable-next-line
    }, [props.eventDates, props.meetings])

    const handleClick = (item: any) => {
        setSingleItem(item)
    }

    return (
        <>
            <RecommendModal />
            {items.length > 0 ? (
                <>
                    {items.map((item, index) => {
                        const isLive = isEventDateLive(item as EventDate)
                        const activateChannel = activateChannelBefore(item as EventDate)
                        const isEventUnlocked = userAccessState.isUnlocked(item as EventDate)
                        const targetId = item.id
                        const link = buildDetailLink(targetId, (item as EventDate).name, "eventdate")
                        const startMoment = moment(((item as EventDate).date ? (item as EventDate).date + "T" : "") + item.start)

                        const linkId =
                            activateChannel && isEventUnlocked && (item as EventDate).channel
                                ? (item as EventDate).channel?.id || item.id
                                : item.id
                        const linkType =
                            activateChannel && isEventUnlocked && (item as EventDate).channel ? "channel" : "eventdate"

                        const speakers = (item as any).participants
                            ? ""
                            : (item as EventDate).persons?.map((speaker: any) => {
                                  let name = [speaker.title, speaker.firstName, speaker.lastName].filter(Boolean).join(" ")

                                  let position = [speaker.position, speaker.organization]
                                      .filter(Boolean)
                                      .join(" " + branding.communicationArea.personCompanyLink + " ")

                                  return " " + name + (position ? " (" + position + ")" : "")
                              })

                        let content = (
                            <div
                                style={{
                                    borderBottom:
                                        props.orgaDetailPage && index === items.length - 1
                                            ? ""
                                            : "1px solid " + branding.listDividerColor,
                                    color: branding.mainInfoColor,
                                    padding: "25px 0",
                                    position: "relative"
                                }}
                            >
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div style={{ width: "100px" }}>
                                        <EventDateImageContainer orgaDetailPage={props.orgaDetailPage}>
                                            {props.orgaDetailPage ? (
                                                <DateBox>
                                                    <Day>{startMoment.format("DD")}</Day>
                                                    <Month>{startMoment.format("MMM").slice(0, 3).toUpperCase()}</Month>
                                                </DateBox>
                                            ) : (
                                                <EventDateImage
                                                    src={
                                                        (item as EventDate).logoUrl && (item as EventDate).logoUrl !== ""
                                                            ? (item as EventDate).logoUrl
                                                            : "/branding/applicationMedia/no-event-default-image.png"
                                                    }
                                                    alt={(item as EventDate).logoText}
                                                />
                                            )}
                                        </EventDateImageContainer>
                                    </div>
                                    <ListEventDateEntryDataContainer>
                                        <EventDateFavBookmarkRoot1>
                                            <div>
                                                {props.orgaDetailPage ? (
                                                    <>
                                                        <Title item={item} />
                                                        <DateTimeAndLocation
                                                            item={item}
                                                            mySchedule={props.mySchedule}
                                                            orgaDetailPage
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        <DateTimeAndLocation item={item} mySchedule={props.mySchedule} />
                                                        <Title item={item} />
                                                    </>
                                                )}

                                                {speakers && speakers.length > 0 && <SpeakersDiv>{"" + speakers}</SpeakersDiv>}

                                                {branding.programSchedule.eventdateDescriptionVisible &&
                                                    !props.orgaDetailPage && (
                                                        <DescriptionRoot className="list-view">
                                                            <div style={{ fontSize: "12px" }}> {(item as EventDate).teaser} </div>
                                                        </DescriptionRoot>
                                                    )}

                                                {(item as any).locationName && !props.orgaDetailPage && (
                                                    <DescriptionRoot className="list-view">
                                                        <div
                                                            style={{
                                                                fontSize: "12px",
                                                                color: branding.sideIconBar.sideIconColorDark
                                                            }}
                                                        >
                                                            {IconLocation({ fill: branding.sideIconBar.sideIconColorDark })}{" "}
                                                            {(item as any).locationName}{" "}
                                                        </div>
                                                    </DescriptionRoot>
                                                )}

                                                {(item as EventDate).accessProvider !== AccessProvider.PUBLIC &&
                                                    !(item as any).participants &&
                                                    !props.orgaDetailPage && (
                                                        <PrivateEventMarker
                                                            layout="list-layout"
                                                            accessProvider={(item as EventDate).accessProvider}
                                                        />
                                                    )}

                                                <BadgeArea
                                                    categories={(item as EventDate).categories || []}
                                                    marginTop={props.orgaDetailPage ? "18px" : "8px"}
                                                    maxBadgeCount={branding.programPageContent.liveSessionsMaxBadgeCount}
                                                />
                                            </div>
                                        </EventDateFavBookmarkRoot1>
                                        {props.pointBadgeData &&
                                            (item as EventDate).categories
                                                ?.map((x: any) => x.alias)
                                                ?.includes(branding.programSchedule.pointsBadgeCategoryAlias) && (
                                                <PointsBadge
                                                    textColor={props.pointBadgeData.colorText}
                                                    fillColor={props.pointBadgeData.colorFill}
                                                    borderColor={props.pointBadgeData.colorBorder}
                                                >
                                                    {branding.programSchedule.pointsBadgeText}
                                                </PointsBadge>
                                            )}
                                    </ListEventDateEntryDataContainer>

                                    {isLive && (
                                        <div
                                            style={{
                                                width: "150px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center"
                                            }}
                                        >
                                            <EventDateBadgeWrapper1>
                                                <EventDateBadgeDot1 />
                                                {branding.sideIconBar.liveMenuText}
                                            </EventDateBadgeWrapper1>
                                        </div>
                                    )}
                                    {}
                                    <div className="d-flex align-items-start" style={{ width: "60px" }}>
                                        {(item as any).participants ? (
                                            <EventDateFavBookmarkRoot1
                                                className={"center-inside"}
                                                style={{ justifyContent: "flex-end" }}
                                            >
                                                <div style={{ cursor: "pointer" }} onClick={() => {}}>
                                                    {IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}
                                                </div>
                                            </EventDateFavBookmarkRoot1>
                                        ) : (
                                            <EventDateFavBookmarkRoot1
                                                className={"center-inside"}
                                                style={{ justifyContent: "flex-end" }}
                                            >
                                                <CustomOverlayTrigger
                                                    trigger={["click", "focus", "hover"]}
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip
                                                            id="button-tooltip-share"
                                                            style={{ fontFamily: branding.font1, zIndex: 1100 }}
                                                        >
                                                            {branding.backofficeStaff.share}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div
                                                        style={{ marginRight: "15px" }}
                                                        onClick={(e) => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            if (props.openRecommendModalInParent)
                                                                props.openRecommendModalInParent(
                                                                    ShareTargetType.EVENTDATE,
                                                                    targetId,
                                                                    (item as EventDate).name,
                                                                    link
                                                                )
                                                            else
                                                                showRecommendModal(
                                                                    ShareTargetType.EVENTDATE,
                                                                    targetId,
                                                                    (item as EventDate).name,
                                                                    link
                                                                )
                                                        }}
                                                    >
                                                        {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
                                                    </div>
                                                </CustomOverlayTrigger>
                                                <CustomOverlayTrigger
                                                    trigger={["click", "focus", "hover"]}
                                                    placement="bottom"
                                                    overlay={
                                                        <Tooltip
                                                            id="button-tooltip-favorite"
                                                            style={{ fontFamily: branding.font1, zIndex: 1100 }}
                                                        >
                                                            {favoriteState.is("eventdate", item.id)
                                                                ? branding.organizationDetailPageContent.removeFavoritePerson
                                                                : branding.organizationDetailPageContent.favoritePerson}
                                                        </Tooltip>
                                                    }
                                                >
                                                    <div>
                                                        <BookmarkWithToggle
                                                            newBookmarkItem={true}
                                                            fontSize={"15px"}
                                                            color={branding.sideIconBar.sideIconColorDark}
                                                            stroke={branding.sideIconBar.sideIconColorDark}
                                                            favIconBasic={true}
                                                            type={"eventdate"}
                                                            id={item.id}
                                                            name={(item as EventDate).name}
                                                        />
                                                    </div>
                                                </CustomOverlayTrigger>
                                            </EventDateFavBookmarkRoot1>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )

                        return (item as any).participants ? (
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setShowCalendarEntry(true)
                                    setViewMode(CalendarEntryModalViewMode.VIEW)
                                    handleClick(item)
                                }}
                            >
                                {content}
                            </div>
                        ) : (
                            <DetailNavLink
                                key={index}
                                name={(item as EventDate).name}
                                id={linkId}
                                type={linkType}
                                source="SCHEDULE"
                                onClick={() => {
                                    trackSelectContent(
                                        (item as EventDate).name,
                                        "Details",
                                        activateChannel && isEventUnlocked && (item as EventDate).channel
                                            ? "Channel"
                                            : "Eventdate"
                                    )
                                }}
                            >
                                {content}
                            </DetailNavLink>
                        )
                    })}

                    {items && !props.orgaDetailPage && items?.length === 4 && <div style={{ height: "40px" }} />}
                </>
            ) : (
                <NoResultsContent>{branding.mySchedule.noItemsText}</NoResultsContent>
            )}
            {showCalendarEntry && (
                <CalendarEntryModal2
                    close={() => {
                        setShowCalendarEntry(false)
                        props.calendarModalClose?.()
                    }}
                    viewMode={viewMode}
                    setViewMode={setViewMode}
                    calendarEntry={singleItem}
                    sotUser={[]}
                />
            )}
        </>
    )
}

function changeEntryContentVisibility(
    wrapperElement: HTMLElement | null,
    nameElement: HTMLElement | null,
    speakerElement: HTMLElement | null,
    teaserElement: HTMLElement | null,
    placeholderElement: HTMLElement | null,
    logoElement: HTMLElement | null,
    liveElement: HTMLElement | null,
    coverElement: HTMLElement | null
) {
    if (
        !wrapperElement ||
        !nameElement ||
        !speakerElement ||
        !teaserElement ||
        !placeholderElement ||
        !logoElement ||
        !liveElement ||
        !coverElement
    )
        return
    setVisible(placeholderElement, true)
    let lastVisibleElement: HTMLElement = nameElement
    // content height without padding
    const availableContentHeight = getInnerHeight(wrapperElement)
    let height = getHeightForElement(
        nameElement, //
        // subtract height and margin of the speaker, if we have one, from the available space. because we want it to be visible, if possible
        isVisible(speakerElement) ? availableContentHeight - getHeightPlusMargin(speakerElement) : availableContentHeight,
        1
    )

    // if the new max-height of the name plus the speaker height and position is still bigger then the available space (possible because we want at least one line of the name)
    // => rerun the calculation for name height without considerating the speaker
    if (isVisible(speakerElement) && getHeightPlusMargin(speakerElement) + height > availableContentHeight) {
        height = getHeightForElement(nameElement, availableContentHeight, 1)
    }
    nameElement.style.height = height + "px"

    if (isVisible(speakerElement)) {
        setVisible(
            speakerElement,
            getOffsetTopWithoutParentPadding(speakerElement) + speakerElement.offsetHeight <= availableContentHeight
        )
        if (isVisible(speakerElement)) lastVisibleElement = speakerElement
    }

    if (isVisible(teaserElement)) {
        height = getHeightForElement(teaserElement, availableContentHeight, 0)
        if (height <= 0) setVisible(teaserElement, false)
        else teaserElement.style.height = height + "px"
        if (isVisible(teaserElement)) lastVisibleElement = teaserElement
    }

    if (availableContentHeight - (getOffsetTopWithoutParentPadding(lastVisibleElement) + lastVisibleElement.offsetHeight) < 100)
        wrapperElement.style.display = "flex"
    setVisible(placeholderElement, false)
}

function isVisible(element: HTMLElement): boolean {
    return element.style.display !== "none"
}

function setVisible(element: HTMLElement, visible: boolean) {
    element.style.display = visible ? "" : "none"
}

function getHeightPlusMargin(element: HTMLElement) {
    var computedStyle = getComputedStyle(element)
    return element.offsetHeight + parseFloat(computedStyle.marginTop)
}

function getHeightForElement(element: HTMLElement, innerHeight: number, miniumLineNumbers: number): number {
    const position = getOffsetTopWithoutParentPadding(element)
    const lineHeight = getLineHeightOrFontSize(element)
    const neededLinesForWholeText = Math.floor(element.offsetHeight / lineHeight)

    const lineToUse = Math.min(neededLinesForWholeText, Math.floor((innerHeight - position) / lineHeight))
    return Math.max(lineToUse, miniumLineNumbers) * lineHeight
}

function getInnerHeight(element: HTMLElement): number {
    var computedStyle = getComputedStyle(element)
    return element.clientHeight - (parseFloat(computedStyle.paddingTop) + parseFloat(computedStyle.paddingBottom))
}

function getLineHeightOrFontSize(element: HTMLElement) {
    var computedStyle = getComputedStyle(element)
    var lineHeight = parseFloat(computedStyle.lineHeight)
    if (isNaN(lineHeight)) return parseFloat(computedStyle.fontSize) * 1.2
    return lineHeight
}

function getOffsetTopWithoutParentPadding(element: HTMLElement): number {
    var computedStyleParent = getComputedStyle(element.parentElement!)
    return element.offsetTop - parseFloat(computedStyleParent.paddingTop)
}

interface ConnectedOrganizationsProps {
    eventDate: EventDate
    organizations?: EventDateConnectedOrganization[]
}

function ConnectedOrganizations(props: ConnectedOrganizationsProps) {
    const [isExpanded, setIsExpanded] = useState<boolean>(false)
    const [slicedOrgas, setSlicedOrgas] = useState(props.organizations)
    const [remainingNumOrgas, setRemainingNumOrgas] = useState<number>(0)
    const numVisibleOrgas = 5
    const lang = useLanguageState().getLanguage()

    const sliceOrgas = useCallback(() => {
        setSlicedOrgas(props.organizations?.slice(0, numVisibleOrgas))
        let remaining = props.organizations?.slice(numVisibleOrgas, props.organizations?.length).length || 0
        setRemainingNumOrgas(remaining)
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        sliceOrgas()
        // eslint-disable-next-line
    }, [])

    if (!props.organizations?.length) return null
    return (
        <EventDateConnectedOrganizationsWrapper>
            {slicedOrgas?.map((organization: EventDateConnectedOrganization) => {
                return (
                    <CustomOverlayTrigger
                        key={organization.id}
                        trigger={["focus", "hover", "click"]}
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-connected-orga-${organization.id}`} style={{ fontFamily: branding.font1 }}>
                                {organization.name}
                            </Tooltip>
                        }
                    >
                        <DetailNavLink
                            onClick={() => {
                                trackSelectContent(
                                    organization.name,
                                    "Details",
                                    "Organization",
                                    `Eventdate - ${props.eventDate.name}`
                                )
                            }}
                            id={organization.id}
                            name={organization.name}
                            type={"organization"}
                        >
                            <EventDateConnectedOrganizationLogo logo={organization?.logoUrl}>
                                {(!organization?.logoUrl || !organization?.logoUrl.length) && organization.name.charAt(0)}
                            </EventDateConnectedOrganizationLogo>
                        </DetailNavLink>
                    </CustomOverlayTrigger>
                )
            })}
            {props.organizations.length > numVisibleOrgas && !isExpanded && (
                <CustomOverlayTrigger
                    trigger={["focus", "hover", "click"]}
                    placement="top"
                    overlay={
                        <Tooltip id={"expand-tooltip"} style={{ fontFamily: branding.font1 }}>
                            {branding.programSchedule.expandConnectedOrganizationsLabel}
                        </Tooltip>
                    }
                >
                    <ToggleOrgasListButton
                        onClick={(e: React.SyntheticEvent) => {
                            setIsExpanded(true)
                            e.preventDefault()
                            setSlicedOrgas(props.organizations)
                        }}
                    >
                        {`+ ${remainingNumOrgas}`}
                    </ToggleOrgasListButton>
                </CustomOverlayTrigger>
            )}

            {isExpanded && (
                <CustomOverlayTrigger
                    trigger={["focus", "hover", "click"]}
                    placement="top"
                    overlay={
                        <Tooltip id={"collapse-tooltip"} style={{ fontFamily: branding.font1 }}>
                            {branding.programSchedule.collapseConnectedOrganizationsLabel}
                        </Tooltip>
                    }
                >
                    <ToggleOrgasListButton
                        onClick={(e: React.SyntheticEvent) => {
                            e.preventDefault()
                            setIsExpanded(false)
                            sliceOrgas()
                        }}
                    >
                        <IconArrowUpLangSwitcher fill="#727272" width="14px" height="14px" />
                    </ToggleOrgasListButton>
                </CustomOverlayTrigger>
            )}
        </EventDateConnectedOrganizationsWrapper>
    )
}
