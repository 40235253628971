import { useLocalAudioInputActivityPreview } from "amazon-chime-sdk-component-library-react"
import { useRef, useState } from "react"
import branding from "../../../branding/branding"
import { IconMicrophoneOff } from "../../../ui/Icons"
import { useDevicesV2 } from "../../hooks/useDevices"
import ActivityBar from "../ActivityBar"
import MicSelection from "../MicSelection/MicSelection"
import SecondaryButton from "../Shared/SecondaryButton"
import SpeakerSelection from "../SpeakerSelection/SpeakerSelection"
import {
    DevicePermissionMessage,
    DevicePermissionMessageContainer,
    DeviceSettingsRoot,
    SettingsGroup,
    TestSpeakerButtonContainer
} from "./AudioSettings.styled"

function AudioSettings() {
    const activityBarRef = useRef<HTMLDivElement>()
    useLocalAudioInputActivityPreview(activityBarRef)
    const [hasMicrophonePermission] = useState(true) // TODO:
    const devices = useDevicesV2()
    devices.ensureDevices()

    return (
        <>
            {hasMicrophonePermission ? (
                <DeviceSettingsRoot>
                    <SettingsGroup>
                        <MicSelection />
                        <ActivityBar ref={activityBarRef} />
                    </SettingsGroup>
                    <SettingsGroup>
                        <SpeakerSelection />
                        <TestSpeakerButtonContainer>
                            <SecondaryButton
                                onClickHandler={devices.testCurrentAudioOutputDevice}
                                text={branding.audioVideoSettings.testSpeakerLabel}
                            />
                        </TestSpeakerButtonContainer>
                    </SettingsGroup>
                </DeviceSettingsRoot>
            ) : (
                <DeviceSettingsRoot>
                    <DevicePermissionMessageContainer>
                        <DevicePermissionMessage>
                            <IconMicrophoneOff fill="#fff" width="25px" height="25px" />
                        </DevicePermissionMessage>
                        <DevicePermissionMessage>
                            {branding.communicationAreaAudioVideoSettingsAudioPermissionMessage}
                        </DevicePermissionMessage>
                    </DevicePermissionMessageContainer>
                </DeviceSettingsRoot>
            )}
        </>
    )
}

export default AudioSettings
