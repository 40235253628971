import { detect } from "detect-browser"
import {
    DevicePermissionMessage,
    DevicePermissionMessageContainer,
    DeviceSettingsRoot,
    SettingsGroup
} from "../AudioSettings/AudioSettings.styled"
import { NoContentShift } from "../BackgroundSettings/BackgroundSettings.styled"
import PreviewVideo from "../PreviewVideo/PreviewVideo"
import { PreviewVideoContainer, PreviewVideoOverlay } from "./VideoSettings.styled"
import branding from "../../../branding/branding"
import { SettingsLabel } from "../Settings/Settings.styled"
import CameraSelection from "../CameraSelection/CameraSelection"
import { useEffect, useState } from "react"
import { usePreMeetingSettingContext } from "../../context/PreMeetingSettingsContext"
import { IconCameraOff } from "../../../ui/Icons"
import { useDevicesV2 } from "../../hooks/useDevices"

function VideoSettings() {
    const browserInfo = detect()
    const preMeetingSettingsContext = usePreMeetingSettingContext()
    const [hasCameraPermission, setHasCameraPermission] = useState(true)
    const devices = useDevicesV2()

    useEffect(() => {
        preMeetingSettingsContext.applyStoredVideoSettings()
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        devices.currentVideoInputDevice() ? setHasCameraPermission(true) : setHasCameraPermission(false)
        // eslint-disable-next-line
    }, [devices.currentVideoInputDevice()])

    return (
        <>
            {hasCameraPermission ? (
                <DeviceSettingsRoot>
                    <SettingsGroup>
                        <PreviewVideoContainer>
                            <NoContentShift browser={browserInfo?.name}>
                                <PreviewVideo />
                                <PreviewVideoOverlay>
                                    <span> {branding.audioVideoSettings.preview}</span>
                                </PreviewVideoOverlay>
                            </NoContentShift>
                        </PreviewVideoContainer>
                        <SettingsLabel>{branding.audioVideoSettings.videoInputLabel}</SettingsLabel>
                        <CameraSelection />
                    </SettingsGroup>
                </DeviceSettingsRoot>
            ) : (
                <DeviceSettingsRoot>
                    <DevicePermissionMessageContainer>
                        <DevicePermissionMessage>
                            <IconCameraOff fill="#fff" width="25px" height="25px" />
                        </DevicePermissionMessage>
                        <DevicePermissionMessage>
                            {branding.communicationAreaAudioVideoSettingsVideoPermissionMessage}
                        </DevicePermissionMessage>
                    </DevicePermissionMessageContainer>
                </DeviceSettingsRoot>
            )}
        </>
    )
}

export default VideoSettings
