import branding from "../branding/branding"
import { getAppUrl, getEnvironment, getProtocolAndHost } from "../environments"
import { getActiveLanguage } from "../globalStates/LanguageState"
import { getTimezoneOffest } from "../utils/DateUtils"
import { defaultLogger as logger } from "../globalStates/AppState"
import FileSaver from "file-saver"

// version is set during the build process, so on dev environment we need to get application version from the package.json
let version
if (getEnvironment() === "dev") {
    const packageJson = require("../../package.json")
    version = packageJson.version
} else version = document.getElementById("version")?.getAttribute("data-value")

export const API_VERSION = 40
export const APP_VERSION = version
export const localStorageKey = "virtualGuide-loggedInUser"
export const seriesOfTopicsName = branding.configuration.sotName
export const seriesOfTopicsAccessToken = branding.configuration.sotAccessToken
export const topic = branding.configuration.topicName
export const userPoolId = branding.configuration.userPoolName

/* #region  DEFAULT PARAMETERS */
export function getDefaultParams() {
    const defaultParams = {
        topic: topic,
        os: "web",
        appUrl: getAppUrl(),
        lang: getActiveLanguage(),
        apiVersion: API_VERSION.toString(),
        timezoneOffset: getTimezoneOffest().toString()
    }
    return defaultParams
}

const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
    // API Usage Tracking Headers
    "EC-Client": `EventGuide/${APP_VERSION}[${API_VERSION}]`,
    "EC-Client-Branding": topic
}
/* #endregion */

/* #region  FETCH DATA WEBSERVICE */

const searchCache: Map<string, any> = new Map()
export async function fetchDataWebService(path: string, params: object | null, signal?: AbortSignal, useCache?: boolean) {
    const protocolAndHost = getProtocolAndHost()

    let combinedParams = params ? { ...getDefaultParams(), ...params } : getDefaultParams()

    var formBody = new URLSearchParams()
    Object.entries(combinedParams).forEach((value) => {
        formBody.append(value[0], value[1])
    })
    const cacheKey = path + formBody.toString()
    if (useCache && searchCache.has(cacheKey)) {
        return searchCache.get(cacheKey)
    }

    const accessDataString = localStorage.getItem(localStorageKey)
    const token = accessDataString ? JSON.parse(accessDataString).jwtToken : null
    const accessHeaders = {
        beConnectionToken: token ? token : seriesOfTopicsAccessToken
    }

    defaultHeaders["Content-Type"] = "application/x-www-form-urlencoded; charset=UTF-8"

    const headers = { ...accessHeaders, ...defaultHeaders }

    const req = fetch(`${protocolAndHost}/webservice${path}`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        headers: headers,
        body: formBody,
        signal: signal
    })

    const resp = await req
    if (resp.status === 403) {
        forceLogout("logoutReasonForbidden")
    } else {
        if (resp.status !== 200) {
            throw Error("Could not load data.")
        }
    }
    const jsonResp = await resp.json()
    searchCache.set(cacheKey, jsonResp)
    return jsonResp
}
/* #endregion */

/* #region  FETCH DATA REST */
export async function fetchDataRest(
    path: string,
    queryParams: object | null,
    method: string = "POST",
    requestBody?: object,
    responseCallback?: Function,
    dontForceLogout?: boolean,
    customHeaders?: object,
    signal?: AbortSignal
) {
    const protocolAndHost = getProtocolAndHost()
    const accessDataString = localStorage.getItem(localStorageKey)
    const token = accessDataString ? JSON.parse(accessDataString).jwtToken : null

    const accessHeaders = {
        beConnectionToken: token ? token : seriesOfTopicsAccessToken
    }

    defaultHeaders["Content-Type"] = "application/json"

    const headersMerged = { ...defaultHeaders, ...customHeaders }

    const headers = { ...accessHeaders, ...headersMerged }

    function makeParams(params: object | null): string {
        let combinedParams = getDefaultParams()
        if (params) {
            combinedParams = { ...combinedParams, ...params }
        }
        var paramsValue = new URLSearchParams()
        Object.entries(combinedParams).forEach((value) => {
            paramsValue.append(value[0], value[1])
        })
        return "?" + paramsValue
    }

    const req = fetch(protocolAndHost + `/rest${path}${makeParams(queryParams)}`, {
        method: method,
        mode: "cors",
        cache: "no-cache",
        headers: headers,
        signal: signal,
        body: method !== "GET" && requestBody ? JSON.stringify(requestBody) : null
    })

    const resp = await req
    if (resp.status === 403) {
        if (dontForceLogout) {
            return await createBackendErrorResponse(resp)
        }

        forceLogout("logoutReasonForbidden")
    } else {
        if (responseCallback && !dontForceLogout) {
            return await responseCallback(resp)
        } else {
            if (resp.status >= 200 && resp.status < 300 && resp.status !== 204) {
                return await resp.json()
            } else {
                return await createBackendErrorResponse(resp)
            }
        }
    }
}
/* #endregion */

/* #region  HANDLING ERRORS */
export interface BackendServiceError {
    httpStatus: number
    httpStatusText: string
    responseJson: any
}

export interface DynamoDBErrors {
    errors: {
        errorType: string
    }[]
}
interface errorDynamoDBObjectProps {
    message: string
    params: any
    error: any
}
export function errorDynamoDBmessage(props: errorDynamoDBObjectProps) {
    let errorMessage: string
    let errorStack: any
    if ((props.error as DynamoDBErrors) && props.error.errors) {
        errorMessage = props.error.errors[0].errorType
        errorStack = props.error as DynamoDBErrors
    } else {
        errorMessage = props.error
        errorStack = props.error.message
    }
    logger.error({
        message: props.message,
        request: "graphql",
        params: props.params,
        errorMessage: errorMessage,
        errorStack: errorStack
    })
    // You can not process from here, please log in again.
    if (errorMessage === "No current user") {
        forceLogout("logoutReasonSessionTimeout")
    }
}

const createBackendErrorResponse = async (response: any) => {
    try {
        const responseJson = await response.json()
        return { httpStatus: response.status, httpStatusText: response.statusText, responseJson: responseJson }
    } catch {
        return { httpStatus: response.status, httpStatusText: response.statusText, responseJson: null }
    }
}
/* #endregion */

/* #region  SAVE FILE */
export async function saveFile(resp: Response, fileName: string): Promise<string> {
    const blob = await resp.blob()
    FileSaver.saveAs(blob, fileName)
    return "DONE"
}
/* #endregion */

/* #region  FORCE LOGOUT */
function forceLogout(logoutReason: string) {
    if (localStorage.getItem("logoutInProcess")) {
        return
    }

    if (localStorage.getItem(localStorageKey)) {
        localStorage.removeItem(localStorageKey)
        localStorage.setItem("logoutReason", logoutReason)
        window.location.reload()
    }
}
/* #endregion */
