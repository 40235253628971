import { loadEventDateDetails } from "../../backendServices/EventdateServices"
import { loadExhibitorData } from "../../backendServices/ExhibitorServices"
import { findCalendarEntryById } from "../../backendServices/GraphQLServices"
import { AttendeeData } from "../../backendServices/MeetingServices"
import branding from "../../branding/branding"

import { buildDetailLink } from "../../contentArea/detailPages/DetailNavLink"
import { BreadcrumbItem } from "../../navigationArea/Breadcrumb"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import { getMeetingKindFromExternalMeetingId } from "./getMeetingKindFromExternalMeetingId"

export async function generateBreadCrumb(roomName: string, attendees?: AttendeeData[], profileId?: string) {
    let breadcrumb: BreadcrumbItem[] = []
    const meetingKind = getMeetingKindFromExternalMeetingId(roomName)
    const locations = calcBreadcrumbLocations()
    if (meetingKind === "virtualCafe") {
        loop: for (const meetingRoomGroup of branding.meetingRoomGroups) {
            for (const meetingRoom of meetingRoomGroup.meetingRooms) {
                if (meetingRoom.id === roomName.slice(3)) {
                    // this is hack, as url's changed
                    locations[0] = "/meetings"
                    locations[1] = `/meetings/${meetingRoomGroup.id}`
                    breadcrumb = [
                        {
                            to: locations[0],
                            name: branding.sideIconBar.virtualcafeMenuText
                        },
                        {
                            to: locations[1],
                            name: meetingRoomGroup.title
                        },
                        {
                            to: locations[2],
                            name: meetingRoom.title
                        }
                    ]
                    break loop
                }
            }
        }
    } else if (meetingKind === "showroom") {
        const organizationid = roomName.slice(3)
        const exhibitorData = await loadExhibitorData({ organizationid: organizationid, hideNewsdata: true })

        if (exhibitorData.content) {
            breadcrumb = [
                {
                    to: "/",
                    name: branding.conferenceTexts.showroomTitle + " - " + exhibitorData.content.name
                }
            ]
        } else {
            breadcrumb = [
                {
                    to: "/",
                    name: branding.conferenceTexts.showroomTitle + " - " + branding.conferenceTexts.showroomUnknownCompanyText
                }
            ]
        }
    } else if (meetingKind === "greenroom") {
        breadcrumb = [
            {
                to: "/",
                name: branding.conferenceTexts.masterclassTitle
            },
            {
                to: "/",
                name: branding.conferenceTexts.greenRoomTitle
            }
        ]
    } else if (meetingKind === "roundtable") {
        breadcrumb = [
            {
                to: "/",
                name: branding.conferenceTexts.roundtableTitle
            }
        ]
    } else if (meetingKind === "conferenceroom") {
        /** We don't have a service currently for getting conference room names.
          I created a ticket: https://corussoft.atlassian.net/browse/BACKEND-689 **/
        breadcrumb = [
            {
                to: "/",
                name: branding.conferenceTexts.conferenceRoomTitle
            }
        ]
    } else if (meetingKind === "breakout") {
        const params = {
            id: roomName.slice(3),
            rootCategories: branding.eventDateDetailPageContent.rootCategories as any,
            showCategoryHierarchy: branding.eventDateDetailPageContent.showCategoryHierarchy || (false as any)
        }
        const eventDateDetails = await loadEventDateDetails(params)
        if (eventDateDetails.content) {
            breadcrumb = [
                {
                    to: "/",
                    name: branding.conferenceTexts.breakoutTitle
                },
                {
                    to: buildDetailLink(eventDateDetails.content.id, eventDateDetails.content.name, "eventdate"),
                    name: eventDateDetails.content.name
                }
            ]
        } else {
            breadcrumb = [
                {
                    to: "/",
                    name: branding.conferenceTexts.breakoutTitle
                }
            ]
        }
    } else if (meetingKind === "call") {
        let name = branding.conferenceTexts.callWith
        let interlocutor = attendees && attendees.filter((attendee: AttendeeData) => attendee?.id !== profileId)[0]

        if (interlocutor) {
            name += interlocutor.name
        } else {
            name = ""
        }

        breadcrumb = [
            {
                to: "/",
                name: name
            }
        ]
    } else if (meetingKind === "calenderEntry") {
        let data = await findCalendarEntryById(roomName.slice(3))
        if (data) {
            breadcrumb = [
                {
                    to: "/",
                    name: data.title
                }
            ]
        } else {
            breadcrumb = [
                {
                    to: "/",
                    name: "Meeting"
                }
            ]
        }
    }
    // eslint-disable-next-line

    return breadcrumb
}
