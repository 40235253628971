import React, { Dispatch, SetStateAction, useState } from "react"
import { Contact, InvitePerson } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { InputProps, radioButtons } from "./CalendarEntryModalData2"
import { CalendarEntryParticipationStatus as CalendarEntryStatus } from "../../../API"
import {
    Background,
    InputTitle,
    InputTitleWrapper,
    MandatoryEntry,
    ParticipantsListWrapper,
    RadioInput,
    RadioLabel,
    RadioWrapper
} from "./CalendarEntryModal.styled"
import { MeetingSlotsDay } from "../../MeetingSlots"
import { User } from "../../../globalStates/LoggedInUser"
import ModalParticipantButton from "./ParticipantButton"
import {
    CalendarEntryModalViewMode,
    createPersonLabel,
    Location,
    LocationPickerMode,
    removePerson,
    renderInput
} from "./ModalFunctions"
import { CalendarEntry, UserBusyTime } from "../../../backendServices/GraphQLServices"
import { Obligations } from "../CalendarEntryModal"
import { IconTimezoneToggle, IconTimezoneToggleSelected } from "../../Icons"

interface ModalCustomItemProps {
    item: InputProps
    setOpenAvailabilityCalendar: Dispatch<SetStateAction<boolean>>
    personClicked?: (person: Contact) => void
    contactsInvited: InvitePerson[]
    setContactsInvited: Dispatch<SetStateAction<InvitePerson[]>>
    meetingOrganizer?: InvitePerson
    user: User | undefined
    predefinedStartTime?: Date
    meetingSlotsTable?: MeetingSlotsDay[]
    time?: string
    viewMode?: CalendarEntryModalViewMode
    date: Date | null
    setDate: Dispatch<SetStateAction<Date | null>>
    startTime: Date | null
    setStartTime: Dispatch<SetStateAction<Date | null>>
    endTime: Date | null
    setEndTime: Dispatch<SetStateAction<Date | null>>
    title: string
    setTitle: Dispatch<SetStateAction<string>>
    selectedTimezone: string
    setSelectedTimezone: Dispatch<SetStateAction<string>>
    setShowErrorModal: Dispatch<SetStateAction<boolean>>
    setNote: Dispatch<SetStateAction<string | undefined>>
    note: string | undefined
    calendarEntry?: CalendarEntry
    closeModal: () => void
    setIsVirtualMeeting: Dispatch<SetStateAction<boolean>>
    isVirtualMeeting: boolean
    location?: Location
    setLocation: Dispatch<SetStateAction<Location | undefined>>
    setErrorModalMessage: Dispatch<SetStateAction<string>>
    copy: UserBusyTime[]
    canEditMeeting: boolean
    setCanEditMeeting: Dispatch<SetStateAction<boolean>>
    meetings: Obligations[]
    showErrorModal?: boolean
    userStatus?: CalendarEntryStatus
}

const ModalCustomInput = (props: ModalCustomItemProps) => {
    const participantLimit = branding.configuration.calendarEntryParticipantLimit
    const [selected, setSelected] = useState<string>(LocationPickerMode.STAND)
    const [currentLocation, setCurrentLocation] = useState<string>("")

    const handleChange = (id: string) => {
        setSelected(id)
        setCurrentLocation("")
    }

    return (
        <Background width={props.item.inputWidth}>
            <InputTitleWrapper justifyContent={props.item.showParticipants ? "space-between" : ""}>
                <InputTitle>
                    {props.item.label}
                    {props.item.inputType === "search" && "(" + props.contactsInvited.length + "/" + participantLimit + ")"}
                    {props.item.required && <MandatoryEntry>*</MandatoryEntry>}
                </InputTitle>
                {props.item.showRadioInput &&
                    props.viewMode !== CalendarEntryModalViewMode.VIEW &&
                    radioButtons(branding)?.map((button) => (
                        <RadioWrapper>
                            <RadioInput onClick={() => handleChange(button.id)}>
                                {selected === button.id
                                    ? IconTimezoneToggleSelected({
                                          fill: branding.calendarEntryModalPageContent.locationPickerRadioButtonColor,
                                          width: "15px",
                                          height: "15px"
                                      })
                                    : IconTimezoneToggle({
                                          fill: branding.calendarEntryModalPageContent.locationPickerRadioButtonDefaultColor,
                                          width: "15px",
                                          height: "15px"
                                      })}
                            </RadioInput>
                            <RadioLabel htmlFor={button.id}>{button.text}</RadioLabel>
                        </RadioWrapper>
                    ))}
            </InputTitleWrapper>
            {props.item.showParticipants && (
                <ParticipantsListWrapper>
                    {props.contactsInvited?.map((contact) => {
                        let status = props.copy?.some((item) => item.userId === contact.id) ? "Busy" : "Free"
                        return (
                            <ModalParticipantButton
                                key={contact.id}
                                contact={contact}
                                meetingOrganizer={props.meetingOrganizer}
                                onClickRemovePerson={() => removePerson(contact, props.setContactsInvited, props.contactsInvited)}
                                user={props.user}
                                label={createPersonLabel(contact)}
                                viewMode={props.canEditMeeting ? CalendarEntryModalViewMode.EDIT : props.viewMode}
                                calendarEntry={props.calendarEntry}
                                status={status}
                                participantStatus={(contact as any).status}
                            />
                        )
                    })}
                </ParticipantsListWrapper>
            )}

            {renderInput(
                props.item,
                props.personClicked,
                props.contactsInvited,
                props.predefinedStartTime,
                props.meetingSlotsTable,
                props.viewMode,
                props.date,
                props.setDate,
                props.setStartTime,
                props.startTime,
                props.endTime,
                props.setEndTime,
                selected,
                props.location,
                props.setLocation,
                props.setTitle,
                props.title,
                props.selectedTimezone,
                props.setSelectedTimezone,
                props.setShowErrorModal,
                props.setNote,
                props.note,
                props.calendarEntry,
                props.closeModal,
                props.setIsVirtualMeeting,
                props.isVirtualMeeting,
                props.setErrorModalMessage,
                props.canEditMeeting,
                currentLocation,
                setCurrentLocation,
                props.showErrorModal,
                props.userStatus
            )}
        </Background>
    )
}

export default ModalCustomInput
