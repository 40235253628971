import styled, { css } from "styled-components"

export const RemoteAvatarRoot = styled.div<{ isHandRaised: boolean; isSpeaking: boolean }>`
    background-color: #37383a;
    height: 100%;
    width: 100%;
    position: relative;
    margin: 0;

    /* Raised hand border */
    ${(props) =>
        props.isHandRaised &&
        css`
            -webkit-box-shadow: inset 0px 0px 0px 3px rgba(238, 203, 17, 1);
            -moz-box-shadow: inset 0px 0px 0px 3px rgba(238, 203, 17, 1);
            box-shadow: inset 0px 0px 0px 3px rgba(238, 203, 17, 1);
        `}

    /* Speaking border */
    ${(props) =>
        props.isSpeaking &&
        !props.isHandRaised &&
        css`
            -webkit-box-shadow: inset 0px 0px 0px 3px rgba(93, 177, 48, 1);
            -moz-box-shadow: inset 0px 0px 0px 3px rgba(93, 177, 48, 1);
            box-shadow: inset 0px 0px 0px 3px rgba(93, 177, 48, 1);
            transition: opacity 0.3s ease-in-out;
        `}
`

export const RemoteAvatarRow = styled.div``

export const AvatarContainer = styled.div<{ isHandRaised: boolean; isSpeaking: boolean; showAvatarBorderAnimations?: boolean }>`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;

    // Currently not in use
    /* Raised hand border */
    ${(props) =>
        props.isHandRaised &&
        props.showAvatarBorderAnimations &&
        css`
            border: 3px solid #eecb11;
            box-shadow: 0px -1px 42px 0px rgba(238, 203, 17, 0.5);
            -webkit-box-shadow: 0px -1px 42px 0px rgba(238, 203, 17, 0.5);
            -moz-box-shadow: 0px -1px 42px 0px rgba(238, 203, 17, 0.5);
        `}

    /* Speaking border */
    ${(props) =>
        props.showAvatarBorderAnimations &&
        props.isSpeaking &&
        !props.isHandRaised &&
        css`
            border: 3px solid #5db130;
            box-shadow: 0px -1px 42px 0px rgba(93, 177, 48, 0.5);
            -webkit-box-shadow: 0px -1px 42px 0px rgba(93, 177, 48, 0.5);
            -moz-box-shadow: 0px -1px 42px 0px rgba(93, 177, 48, 0.5);
        `}
`

export const AvatarNamePlate = styled.div`
    color: #fff;
    position: absolute;
    left: 10px;
    bottom: 10px;
    border-radius: 5px;
    background-color: #202428;
    font-size: 14px;
    line-height: 17px;
    padding: 3px 3px 3px 6px;
    display: flex;
    flex-direction: row;
    align-items: center;

    /* Not used anymore, but was used for MicrophoneActivity
     icon which is now replaced in the Attendees list */
    .activity {
        width: 23px;
        display: inline-block;

        .ch-bg-volume-wrapper {
            width: 23%;
            left: 36%;
        }
        .ch-bg-volume-fill {
            background-color: #5db130;
        }
    }

    .hand {
        fill: #eecb11;

        path {
            fill: #eecb11;
        }
    }
`
export const AvatarNameLabel = styled.div`
    margin-left: 3px;
`
