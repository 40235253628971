import { saveAs } from "file-saver"
import { createEvents, EventAttributes } from "ics"
import { isSafari } from "react-device-detect"
import { CalendarEntry } from "../backendServices/GraphQLServices"
import { EventDate } from "../backendServices/Types"
import branding from "../branding/branding"
import { buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import { defaultLogger as logger } from "../globalStates/AppState"

export const saveMeetings = (meetings: CalendarEntry[]) => {
    const events = meetings.map((x) => toMeeting(x))
    const name = (meetings.length === 1 ? meetings[0].title : "meetings") + ".ics"
    const result = createEvents(events)
    if (result.error) {
        logger.error("ICal saveMeetings", result.error)
    } else {
        logger.info("ICal saveMeetings", result.value)
        const blob = new Blob([result.value!], { type: "text/calendar" })
        saveAs(blob, name)
    }
}

export const saveEvent = (event: EventDate) => {
    const eventToExport = toEvent(event)
    const name = event.name + ".ics"
    const result = createEvents([eventToExport])
    if (result.error) {
        logger.error("ICal saveEvent", result.error)
    } else {
        logger.info("ICal saveEvent", result.value)
        const blob = new Blob([result.value!], { type: "text/calendar" })
        saveAs(blob, name)
    }
}

export const saveEvents = (events: EventDate[]) => {
    const eventsToExport = events.map((x) => toEvent(x))
    const name = (events.length === 1 ? events[0].name : "events") + ".ics"
    const result = createEvents(eventsToExport)
    if (result.error) {
        logger.error("ICal saveEvents", result.error)
    } else {
        logger.info("ICal saveEvents", result.value)
        const blob = new Blob([result.value!], { type: "text/calendar" })
        saveAs(blob, name)
    }
}

const toEvent: (eventDate: EventDate) => EventAttributes = (eventDate) => {
    let start = new Date(eventDate.startdatetime)
    let end = new Date(eventDate.enddatetime)

    if (isSafari) {
        start = new Date(eventDate.startdatetime.replaceAll("-", "/"))
        end = new Date(eventDate.enddatetime.replaceAll("-", "/"))
    }

    var eventDateTemplate = branding.eventDateIcsExport.eventDateIcsTemplate
    var linkHost = window.location.hostname === "localhost" ? "http://localhost:3000" : "https://" + window.location.hostname
    var eventDateTitleTemplate = branding.eventDateIcsExport.eventDateIcsTitle
    var personsString = ""
    var persons = eventDate.persons.map(
        (x) =>
            `${[x.title, x.firstName, x.lastName].filter(Boolean).join(" ")} ` +
            (x.userId
                ? ` -> ${linkHost}${buildDetailLink(x.userId, [x.firstName, x.lastName].filter(Boolean).join(" "), "user")}\n`
                : `-> ${linkHost}${buildDetailLink(x.id, [x.firstName, x.lastName].filter(Boolean).join(" "), "person")}\n`)
    )
    persons.forEach((person) => {
        personsString += person + "\n"
    })
    var description =
        eventDateTitleTemplate +
        eventDateTemplate.replace(
            "{$eventDateLink}",
            `${linkHost}${buildDetailLink(eventDate.id ?? "", eventDate.name ?? "", "eventdate")}`
        )
    description = description.replace("{$eventDateName}", eventDate.name)
    description = description.replace("{$organization}", eventDate.organizationName ?? "")
    description = description.replace(
        "{$organizationLink}",
        eventDate.organizationId
            ? `-> ${linkHost}${buildDetailLink(eventDate.organizationId ?? "", eventDate.organizationName ?? "", "organization")}`
            : ""
    )
    description = description.replace("{$speakers}", eventDate.persons.length === 0 ? "" : personsString)

    if (eventDate.location) {
        description = description.replace("{$eventDateLocation}", eventDate.location)
    } else {
        description = description.replace("{$eventDateLocation}", "")
    }

    if (eventDate.text) {
        description = description.replace("{$description}", eventDate.teaser)
    } else {
        description = description.replace("{$description}", "")
    }

    const event: EventAttributes = {
        start: [start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()],
        startInputType: "utc",
        end: [end.getUTCFullYear(), end.getUTCMonth() + 1, end.getUTCDate(), end.getUTCHours(), end.getUTCMinutes()],
        endInputType: "utc",
        title: eventDate.name,
        description: description
    }
    return event
}

const toMeeting: (meeting: CalendarEntry) => EventAttributes = (meeting) => {
    const start = new Date(meeting.start)
    const end = new Date(meeting.end)

    var meetingTemplate = branding.calendarEntryExport.meetingIcsTemplate
    var linkHost = window.location.hostname === "localhost" ? "http://localhost:3000" : "https://" + window.location.hostname
    var meetingTitle = branding.calendarEntryExport.meetingIcsTitle

    var attendesString = ""
    var attendes = meeting.participants.items
        .filter((x) => x.userId !== meeting.userId)
        .map((x) => x.user.name + ` -> ${linkHost}${buildDetailLink(x.user.id, x.user.name, "user")}\n`)
    attendes.forEach((attende) => {
        attendesString += attende + "\n"
    })

    var description =
        meetingTitle +
        meetingTemplate
            .replace("{$meetingLink}", `${linkHost}?communicationArea=SCHEDULE&type=CONFIRMED`)
            .replace("{$meetingName}", meeting.title)
            .replace(
                "{$organizer}",
                meeting.user.name + ` -> ${linkHost}${buildDetailLink(meeting.user.id, meeting.user.name, "user")}`
            )
            .replace("{$attendes}", attendes.length === 0 ? "" : attendesString)

    if (meeting.description) {
        description = description.replace("{$description}", meeting.description + "\n \n")
    } else {
        description = description.replace("{$description}", "")
    }

    const event: EventAttributes = {
        start: [start.getUTCFullYear(), start.getUTCMonth() + 1, start.getUTCDate(), start.getUTCHours(), start.getUTCMinutes()],
        startInputType: "utc",
        end: [end.getUTCFullYear(), end.getUTCMonth() + 1, end.getUTCDate(), end.getUTCHours(), end.getUTCMinutes()],
        endInputType: "utc",
        title: meeting.title,
        location: meeting.locationName
            ? branding.configuration.ogTitle + ": " + meeting.locationName
            : branding.configuration.ogTitle,
        description: description
    }
    return event
}
