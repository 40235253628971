import styled from "styled-components"

export const ToggleGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const BackgroundGalleryContainer = styled.div`
    display: grid;
    grid-gap: 3px;
    grid-template-columns: 32.8% 32.8% 32.8%;
    height: 170px;
    overflow-y: auto;
`

export const BackgroundGalleryItem = styled.div<{ image: string; isActive?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 75px;
    background-image: ${(props) => (props.image ? `url(${props.image})` : "none")};
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: ${(props) => (props.isActive ? "inset 0px 0px 0px 2px #6DD400" : "none")};
    transition: box-shadow 0.3s;
    cursor: pointer;
    text-align: center;
    font-size: 12px;
    line-height: 17px;
    background-color: #37383a;
    color: #fff;
    border-radius: 3px;
    position: relative;

    :hover {
        box-shadow: inset 0px 0px 0px 2px #6dd400;
    }

    .actions {
        width: 20px;
        height: 18px;
        position: absolute;
        z-index: 20;
        right: 6px;
        top: 6px;
        svg:hover path {
            fill: red;
        }
        background-color: rgba(0, 0, 0, 0.3);
        border-radius: 3px;
    }
`

export const IconContainer = styled.div`
    cursor: pointer;
    float: right;
    margin-bottom: 10px;

    svg {
        transition: 0.3s;
    }
    svg:hover {
        transform: rotate(90deg);
    }
`

export const CustomBackgroundActionsOverlay = styled.div`
    position: absolute;
    top: 0;
    right: 0px;
    bottom: 0;
    left: 0;
    z-index: 10;
    transition: 0.3s;
    border-radius: 3px;
`

export const TrashIconContainer = styled.div``

export const NoContentShift = styled.div<{ browser: any }>`
    ${(props) => {
        if (props.browser === "chrome") {
            return `min-height: 240px;`
        } else if (props.browser === "firefox") {
            return `div {width: 300px; margin: 0 auto}` // Resized preview tile, because on firefox the tile is larger
        } else {
            return `min-height: 240px;`
        }
    }}
`
