import { getActiveLanguage } from "../globalStates/LanguageState"
import { fetchDataRest, fetchDataWebService, topic } from "./BackendServicesUtils"
import { Company, Entity } from "./Types"
import { defaultLogger as logger } from "../globalStates/AppState"
import { getIsMyNetworkingStatusActiveFromLS } from "../globalStates/LoggedInUser"

/*********************************************************************************************
 * EXHIBITOR LIST
 **********************************************************************************************/

export interface ExhibitorsListResponse {
    count: number
    entities: Entity[]
}

export interface ExhibitorListRequestParameter {
    numresultrows: number
    startresultrow: number
    filterlist: string[]
    order: string
    alpha?: string
    desc?: boolean
    /**
     * @title Basis premium number
     * @description Basis premium number for corresponding package
     * @title_de Basis premium number
     * @description_de Basis premium number for corresponding package
     */
    basispremium?: number
}

let cachedResults: Record<string, Promise<ExhibitorsListResponse> | ExhibitorsListResponse> = {}
const createStringKeyFromParams = (params: ExhibitorListRequestParameter) => {
    const keyList: Array<string> = [
        params.numresultrows?.toString(),
        params.startresultrow?.toString(),
        params.filterlist?.toString(),
        params.order,
        params.alpha ?? "",
        params.desc ? "desc" : "asc",
        getActiveLanguage(),
        getIsMyNetworkingStatusActiveFromLS()?.toString() || ""
    ]
    return keyList.join("_")
}

export async function loadExhibitorsData(
    params: ExhibitorListRequestParameter,
    signal?: AbortSignal,
    ignoreCache?: boolean
): Promise<ExhibitorsListResponse> {
    try {
        const key = createStringKeyFromParams(params)
        const existingResult = ignoreCache ? undefined : cachedResults[key]

        if (!existingResult) {
            const data = fetchDataWebService("/search", params, signal)
            cachedResults[key] = data
            const response = await data
            cachedResults[key] = response
            return response
        } else {
            if (Promise.resolve(existingResult) === existingResult) return await existingResult
            else return existingResult
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, entities: [] }
    }
}

/*********************************************************************************************
 * EXHIBITOR DETAILS
 **********************************************************************************************/
export interface ExhibitorResponse {
    content: Company
}

export interface ExhibitorDetailsParams {
    organizationid: string
    hideNewsdata: boolean
    showAll?: boolean
    showPersonsEventDates?: boolean
    rootCategories?: Array<string>
    showCategoryHierarchy?: boolean
}

export async function loadExhibitorData(params: ExhibitorDetailsParams, signal?: AbortSignal): Promise<ExhibitorResponse> {
    try {
        const data = await fetchDataWebService("/companydetails", params, signal)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/companydetails",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}
export interface ContentManagerLinkObject {
    de: string
    default: string
}
export async function getOrganizationContentManagerlink(
    profileId: string,
    organizationId: string
): Promise<ContentManagerLinkObject | null> {
    const defaultRoute: string = `/organization/topic/${topic}/organization/${organizationId}/contentmanager`
    const data = await fetchDataRest(defaultRoute, {}, "GET")
    if (data) {
        return data
    } else {
        return null
    }
}
