import {
    MicrophoneActivity,
    RemoteVideo,
    useActiveSpeakersState,
    useAttendeeStatus
} from "amazon-chime-sdk-component-library-react"
import { useContext, useEffect } from "react"
import { IconHandYellow } from "../../../ui/Icons"
import { RaisedHandContext } from "../../ConferenceRoom/ConferenceRoom"
import { AvatarNameLabel, AvatarNamePlate } from "../RemoteAvatar/RemoteAvatar.styled"
import { RemoteVideoBorderContainer, RemoteVideoRoot } from "./RemoteVideo.styled"

interface RemoteVideoProps {
    attendee: any
    tileId: number
}

function RemoteVideoCustom(props: RemoteVideoProps) {
    const { attendee } = props
    const raisedHands = useContext(RaisedHandContext)
    const { muted } = useAttendeeStatus(attendee.chimeAttendeeId)
    const activeSpeakers = useActiveSpeakersState()

    useEffect(() => {
        /**This is bad practice. But i don't know a better solution for now. */
        const videoEl: any = document.querySelectorAll("video")
        if (videoEl) {
            videoEl.forEach((el: HTMLVideoElement) => {
                el.setAttribute("disablePictureInPicture", "true")
            })
        }
    }, [])

    return (
        <RemoteVideoRoot>
            <RemoteVideoBorderContainer
                isHandRaised={raisedHands && raisedHands.includes(attendee.id)}
                isSpeaking={(!muted && activeSpeakers && activeSpeakers.indexOf(attendee.chimeAttendeeId) > -1) || false}
            >
                <RemoteVideo tileId={props.tileId} key={props.tileId} />
                <AvatarNamePlate>
                    {raisedHands && raisedHands.includes(attendee.id) && <IconHandYellow height="18px" width="18px" />}
                    <AvatarNameLabel>{attendee.name}</AvatarNameLabel>
                    <MicrophoneActivity className="activity" attendeeId={attendee.chimeAttendeeId} />
                </AvatarNamePlate>
            </RemoteVideoBorderContainer>
        </RemoteVideoRoot>
    )
}

export default RemoteVideoCustom
