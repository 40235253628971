import { createState, State, useState } from "@hookstate/core"
import { DeviceType } from "amazon-chime-sdk-component-library-react/lib/types"
import { detect } from "detect-browser"
import { defaultLogger as logger } from "../../globalStates/AppState"
import TestSound from "../components/TestSound"

// user's selected device in localStorage
export const AudioInputStorageKey = "virtualGuide-audioInput"
export const AudioOutputStorageKey = "virtualGuide-audioOutput"
export const VideoInputStorageKey = "virtualGuide-videoInput"

// available devices
const AudioInputsStorageKey = "virtualGuide-audioInputs"
const AudioOutputsStorageKey = "virtualGuide-audioOutputs"
const VideoInputsStorageKey = "virtualGuide-videoInputs"

const browserCheckResult = detect()
const isFirefox = browserCheckResult && browserCheckResult.type === "browser" && browserCheckResult.name === "firefox"

interface DeviceState {
    videoInputDevices: MediaDeviceInfo[]
    audioInputDevices: MediaDeviceInfo[]
    audioOutputDevices: MediaDeviceInfo[]
    currentVideoInputDevice: DeviceType | null
    currentAudioInputDevice: DeviceType | null
    currentAudioOutputDevice: DeviceType | null
}

interface DeviceContext {
    currentAudioInputDevice: () => DeviceType | null
    currentAudioOutputDevice: () => DeviceType | null
    currentVideoInputDevice: () => DeviceType | null
    audioInputDevices: () => MediaDeviceInfo[]
    audioOutputDevices: () => MediaDeviceInfo[]
    videoInputDevices: () => MediaDeviceInfo[]
    setAudioInputDevice: (deviceId: string, label: string) => void
    setAudioOutputDevice: (deviceId: string, label: string) => void
    setVideoInputDevice: (deviceId: string, label: string) => void
    ensureDevices: () => Promise<void>
    testCurrentAudioOutputDevice: () => void
}

let initialized = false
let isInitializing = false
const useWrapState = (state: State<DeviceState>): DeviceContext => {
    return {
        currentAudioInputDevice: () => {
            return state.get().currentAudioInputDevice
        },
        currentAudioOutputDevice: () => {
            return state.get().currentAudioOutputDevice
        },
        currentVideoInputDevice: () => {
            return state.get().currentVideoInputDevice
        },
        audioInputDevices: () => {
            return state.get().audioInputDevices
        },
        audioOutputDevices: () => {
            return state.get().audioOutputDevices
        },
        videoInputDevices: () => {
            return state.get().videoInputDevices
        },
        setAudioInputDevice: (deviceId: string, label: string) => {
            localStorage.setItem(AudioInputStorageKey, deviceId)
            state.set((prevState) => {
                prevState.currentAudioInputDevice = { deviceId: deviceId, label: label }
                return prevState
            })
        },
        setAudioOutputDevice: (deviceId: string, label: string) => {
            localStorage.setItem(AudioOutputStorageKey, deviceId)
            state.set((prevState) => {
                prevState.currentAudioOutputDevice = { deviceId: deviceId, label: label }
                return prevState
            })
        },
        setVideoInputDevice: (deviceId: string, label: string) => {
            localStorage.setItem(VideoInputStorageKey, deviceId)
            state.set((prevState) => {
                prevState.currentVideoInputDevice = { deviceId: deviceId, label: label }
                return prevState
            })
        },
        ensureDevices: async (localStorageFilled?: boolean, force?: boolean) => {
            if ((initialized || localStorageFilled || isInitializing) && (!force || force === undefined)) {
                return
            }

            isInitializing = true
            const videoInputDevices: MediaDeviceInfo[] = []
            const audioInputDevices: MediaDeviceInfo[] = []
            const audioOutputDevices: MediaDeviceInfo[] = []
            let currentVideoInputDevice: DeviceType | null = null
            let currentAudioInputDevice: DeviceType | null = null
            let currentAudioOutputDevice: DeviceType | null = null
            try {
                const astream = await navigator.mediaDevices.getUserMedia({ audio: true })
                astream.getTracks().forEach((x) => x.stop())
            } catch (e: any) {
                logError(e)
            }
            let noVideo = false
            try {
                const vstream = await navigator.mediaDevices.getUserMedia({ video: true })
                vstream.getTracks().forEach((x) => x.stop())
            } catch (e: any) {
                localStorage.removeItem(VideoInputsStorageKey)
                noVideo = true
                logError(e)
            }
            try {
                const data = await navigator.mediaDevices.enumerateDevices()
                const dataFilter = data.filter((x) => x.label !== "")
                if (dataFilter.length) {
                    videoInputDevices.push(...dataFilter.filter((x) => x.kind === "videoinput"))
                    localStorage.setItem(VideoInputsStorageKey, JSON.stringify(videoInputDevices))

                    let newAudioInputDevices = []
                    newAudioInputDevices.push(...dataFilter.filter((x) => x.kind === "audioinput"))

                    if (newAudioInputDevices.length === 0) {
                        localStorage.removeItem(AudioInputsStorageKey)
                    } else {
                        audioInputDevices.push(...dataFilter.filter((x) => x.kind === "audioinput"))
                        localStorage.setItem(AudioInputsStorageKey, JSON.stringify(audioInputDevices))
                    }

                    let newAudioOutputDevices = []
                    newAudioOutputDevices.push(...dataFilter.filter((x) => x.kind === "audiooutput"))

                    if (newAudioOutputDevices.length === 0) {
                        localStorage.removeItem(AudioOutputsStorageKey)
                    } else {
                        audioOutputDevices.push(...dataFilter.filter((x) => x.kind === "audiooutput"))
                        localStorage.setItem(AudioOutputsStorageKey, JSON.stringify(audioOutputDevices))
                    }
                } else {
                    localStorage.removeItem(VideoInputsStorageKey)
                    localStorage.removeItem(AudioInputsStorageKey)
                    localStorage.removeItem(AudioOutputsStorageKey)
                }
            } catch (e: any) {
                logError(e)
            }

            if (audioInputDevices.length) {
                const audioInputStorage = localStorage.getItem(AudioInputStorageKey)
                const aidIndex = audioInputDevices.findIndex((x) => x.deviceId === audioInputStorage && x.kind === "audioinput")
                const aidSelected = aidIndex === -1 ? 0 : aidIndex
                if (audioInputDevices[aidSelected])
                    currentAudioInputDevice = {
                        deviceId: audioInputDevices[aidSelected].deviceId,
                        label: audioInputDevices[aidSelected].label
                    }
            }

            if (videoInputDevices.length && !noVideo) {
                const videoInputStorage = localStorage.getItem(VideoInputStorageKey)
                const vidIndex = videoInputDevices.findIndex((x) => x.deviceId === videoInputStorage && x.kind === "videoinput")
                const vidSelected = vidIndex === -1 ? 0 : vidIndex
                if (videoInputDevices[vidSelected])
                    currentVideoInputDevice = {
                        deviceId: videoInputDevices[vidSelected].deviceId,
                        label: videoInputDevices[vidSelected].label
                    }
                localStorage.setItem(VideoInputStorageKey, currentVideoInputDevice?.deviceId!)
            }

            if (!isFirefox && audioOutputDevices.length) {
                const audioOutputStorage = localStorage.getItem(AudioOutputStorageKey)
                const aodIndex = audioOutputDevices.findIndex(
                    (x) => x.deviceId === audioOutputStorage && x.kind === "audiooutput"
                )
                const aodSelected = aodIndex === -1 ? 0 : aodIndex
                if (audioOutputDevices[aodSelected])
                    currentAudioOutputDevice = {
                        deviceId: audioOutputDevices[aodSelected].deviceId,
                        label: audioOutputDevices[aodSelected].label
                    }
            }

            state.set({
                videoInputDevices: videoInputDevices,
                audioInputDevices: audioInputDevices,
                audioOutputDevices: audioOutputDevices,
                currentVideoInputDevice: currentVideoInputDevice,
                currentAudioInputDevice: currentAudioInputDevice,
                currentAudioOutputDevice: currentAudioOutputDevice
            })
            initialized = true
        },
        testCurrentAudioOutputDevice: () => {
            new TestSound(state.value.currentAudioOutputDevice?.deviceId || "", 440, 2)
        }
    }
}
const state = createState({
    videoInputDevices: [],
    audioInputDevices: [],
    audioOutputDevices: [],
    currentVideoInputDevice: null,
    currentAudioInputDevice: null,
    currentAudioOutputDevice: null
} as DeviceState)
export const useDevicesV2 = () => useWrapState(useState(state))

function logError(error: Error) {
    // eslint-disable-next-line
    console.error(error)
    logger.error({ message: "ChimeSdkWrapper " + error.name, errorMessage: error.message, errorStack: error.stack })
}
