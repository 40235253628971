// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { useVideoInputs } from "amazon-chime-sdk-component-library-react"
import { BaseSdkProps } from "amazon-chime-sdk-component-library-react/lib/components/sdk/Base"
import React, { useEffect, useRef } from "react"
import { useVideoContext } from "../../context/VideoContext"
import { StyledPreview } from "./PreviewVideo.styled"

export const PreviewVideo: React.FC<BaseSdkProps> = (props) => {
    const { selectedDevice } = useVideoInputs()
    const ref = useRef<HTMLVideoElement>(null)
    const videoContext = useVideoContext()
    const localVideoStatus = videoContext.getLocalVideoStatus()

    useEffect(() => {
        const videoEl = ref.current
        function startPreview() {
            if (!videoEl) {
                return
            }
            videoContext.startVideoPreview(videoEl)
        }

        function stopPreview() {
            if (!videoEl) {
                return
            }
            videoContext.stopVideoPreview(videoEl)
        }

        startPreview()

        return () => {
            stopPreview()
        }

        //eslint-disable-next-line
    }, [localVideoStatus, ref.current, selectedDevice])

    return <StyledPreview {...props} ref={ref} />
}

export default PreviewVideo
