import { BackendServiceError, fetchDataRest, userPoolId } from "./BackendServicesUtils"
import { defaultLogger as logger } from "../globalStates/AppState"
import { getStaticDomain } from "../environments"

export type UserMediaItem = {
    id: string
    purpose: UserMediaPurpose
    url: string
}

export enum UserMediaPurpose {
    LOGO = "LOGO",
    BACKGROUND = "BACKGROUND"
}
/**
 * To extend this type (if needed) please see this page for all possible error codes.
 * https://docs.aws.amazon.com/AmazonS3/latest/API/ErrorResponses.html#ErrorCodeList
 */
export enum S3ErrorCode {
    AccessDenied = "AccessDenied",
    EntityTooSmall = "EntityTooSmall",
    EntityTooLarge = "EntityTooLarge",
    ExpiredToken = "ExpiredToken"
}

export type PresignedURLServiceSuccessResponse = {
    userMediaUrl: string
    endpointUrl: string
    fields: any
}

export type UserMediaUploadServiceSuccessResponse = {
    status: number
    ok: boolean
    message: string
    errorCode?: S3ErrorCode
}

export async function getPresignedUrlWithHeaders(
    purpose: UserMediaPurpose,
    profileId: string
): Promise<PresignedURLServiceSuccessResponse | BackendServiceError> {
    const params = { purpose }
    const route: string = `/feusermedia/userpool/${userPoolId}/profile/${profileId}/presignedurl`
    try {
        const response = await fetchDataRest(route, params)
        if ((response as BackendServiceError).httpStatus) {
            // error
            return response
        } else {
            // success
            const userMediaURL = `${getStaticDomain()}/${response.fields.key}`
            return { ...response, userMediaUrl: userMediaURL } as PresignedURLServiceSuccessResponse
        }
    } catch (error: any) {
        logger.error({
            message: "FeUserMedia get presigned URL failed",
            request: route,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })

        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function uploadUserMedia(
    formData: FormData,
    endpointUrl: string
): Promise<UserMediaUploadServiceSuccessResponse | BackendServiceError> {
    const fetchOptions: RequestInit = {
        method: "POST",
        body: formData,
        mode: "cors",
        headers: {
            "Access-Control-Allow-Origin": "*"
        }
    }

    try {
        const response = await fetch(endpointUrl, fetchOptions)
        if (response.status === 204) {
            return { status: response.status, ok: response.ok, message: "Success", errorCode: undefined }
        } else if (response.status === 400 || response.status === 403) {
            const errorCode = await getErrorCodeFromRESTResponse(response)
            return { status: response.status, ok: false, message: errorCode, errorCode: errorCode }
        } else {
            return { status: response.status, ok: false, message: "Unknown error", errorCode: undefined }
        }
    } catch (error: any) {
        logger.error({
            message: "FeUserMedia file upload failed",
            request: endpointUrl,
            params: formData,
            errorMessage: error.message,
            errorStack: error.stack
        })

        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function getUserMedia(profileId: string): Promise<UserMediaItem[] | BackendServiceError> {
    const route: string = `/feusermedia/userpool/${userPoolId}/profile/${profileId}`
    let userMedia: UserMediaItem[]
    try {
        const response = await fetchDataRest(route, null, "GET")
        if (response?.feUserMedia) {
            userMedia = response?.feUserMedia
            return userMedia
        } else {
            return []
        }
    } catch (error: any) {
        logger.error({
            message: "FeUserMedia list user media failed",
            request: route,
            errorMessage: error.message,
            errorStack: error.stack
        })

        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function deleteUserMedia(
    feUserMediaId: string
): Promise<UserMediaUploadServiceSuccessResponse | BackendServiceError> {
    const route: string = `/feusermedia/userpool/${userPoolId}/feusermedia/${feUserMediaId}`
    try {
        const response = await fetchDataRest(route, null, "DELETE")
        if (response.status === 200) {
            return { status: response.status, ok: response.ok, message: "Success", errorCode: undefined }
        } else if (response.status === 400 || response.status === 403) {
            const errorCode = await getErrorCodeFromRESTResponse(response)
            return { status: response.status, ok: false, message: errorCode, errorCode: errorCode }
        } else {
            return { status: response.status, ok: false, message: "Unknown error", errorCode: undefined }
        }
    } catch (error: any) {
        logger.error({
            message: "FeUserMedia list user media failed",
            request: route,
            errorMessage: error.message,
            errorStack: error.stack
        })

        return { httpStatus: 500 } as BackendServiceError
    }
}

async function getErrorCodeFromRESTResponse(response: Response): Promise<S3ErrorCode> {
    const responseBody = await response.text()
    const parsedBody = new window.DOMParser().parseFromString(responseBody, "text/xml")
    const errorCode = parsedBody.getElementsByTagName("Code")[0].innerHTML
    return errorCode as S3ErrorCode
}
