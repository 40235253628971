import styled from "styled-components"

export const SettingsErrorMessageWrapper = styled.div`
    width: 100%;
    padding: 10px;
    color: #000;
    background-color: #ffcece;
    border-left: 4px solid #ad4137;
    font-size: 12px;
`
