import { createState, State, useState } from "@hookstate/core"
import {
    ActionType,
    MeetingStatus,
    Severity,
    useMeetingManager,
    useNotificationDispatch,
    useRosterState
} from "amazon-chime-sdk-component-library-react"
import { DataMessage, MeetingSessionConfiguration } from "amazon-chime-sdk-js"
import { useHistory } from "react-router-dom"
import { BackendServiceError } from "../../backendServices/BackendServicesUtils"
import { insertMeetingRoomAttendance, removeMeetingRoomAttendance, removeRaisedHand } from "../../backendServices/GraphQLServices"
import { ban, ChimeMeetingData, createOrJoinMeeting, kick, leaveRoom, MeetingKind } from "../../backendServices/MeetingServices"
import branding from "../../branding/branding"
import { useAppState } from "../../globalStates/AppState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { meetingEndedRoute } from "../../navigationArea/RoutePaths"
import { DataMessageType } from "../enums/DataMessageType"
import { EGMeetingStatus } from "../enums/EGMeetingStatus"
import { useMeetingInvitation } from "./MeetingInvitation"

interface StateValues {
    /**
     * If you see that I sliced the externalMeetingId or externalMeetingId
     * this is because of data messages limitations https://github.com/aws/amazon-chime-sdk-js/issues/1955
     */
    externalMeetingId: string | null
    /**
     * If you see that I sliced the externalMeetingId or externalMeetingId
     * this is because of data messages limitations https://github.com/aws/amazon-chime-sdk-js/issues/1955
     */
    externalUserId: string | null
    role: string | null
    initMeetingError: string | null
    meetingKind: MeetingKind | null
    maxAttendees: number
    maxDurationSeconds: number | null
    timeLimitChanged: boolean
    meetingTimeLeft: number | null
    egMeetingStatus: EGMeetingStatus | null
    hasMaxAttendees: boolean
    meetingChangeAccepted: boolean
    isMeetingActive: boolean
    isSwitchingRoom: boolean
    isMeetingMinimized: boolean
    kickOrBanMessage: string | null
    isConferenceOverlayVisible: boolean
    meetingTitle: string | null
    callDuration: number | null
    isUserKickedOrBanned: boolean
}

const getStartValues = (): StateValues => {
    return {
        externalMeetingId: null,
        externalUserId: null,
        role: null,
        initMeetingError: null,
        meetingKind: null,
        maxAttendees: 5,
        maxDurationSeconds: null,
        timeLimitChanged: false,
        meetingTimeLeft: null,
        egMeetingStatus: null,
        hasMaxAttendees: false,
        meetingChangeAccepted: false,
        isMeetingActive: false,
        isSwitchingRoom: false,
        isMeetingMinimized: false,
        kickOrBanMessage: null,
        isConferenceOverlayVisible: false,
        meetingTitle: null,
        callDuration: null,
        isUserKickedOrBanned: false
    }
}

export interface MeetingController {
    initMeeting: (externalMeetingId: string, externalUserId: string) => void
    startMeeting: () => void
    leaveMeeting: (isSwitchingRoom?: boolean) => Promise<void>
    getExternalMeetingId: () => string | null
    getExternalUserId: () => string | null
    getIsMod: () => boolean | null
    getInitMeetingError: () => string | null
    getEGMeetingStatus: () => EGMeetingStatus | null
    setEGMeetingStatus: (egMeetingStatus: EGMeetingStatus) => void
    getMeetingKind: () => MeetingKind | null
    getMeetingTimeLeft: () => number | null
    getTimeLimitChanged: () => boolean
    getMaxAttendees: () => number
    getHasMaxAttendees: () => boolean
    getIsMeetingChangeAccepted: () => boolean
    setIsMeetingChangeAccepted: (accepted: boolean) => void
    getIsMeetingActive: () => boolean
    getIsSwitchingRoom: () => boolean
    setIsSwitchingRoom: (value: boolean) => void
    setShowConferenceOverlay: (value: boolean) => void
    setKickOrBanMessage: (value: string) => void
    getKickOrBanMessage: () => string | null
    isConferenceOverlayVisible: () => boolean
    setIsConferenceOverlayVisible: (value: boolean) => void
    getMeetingTitle: () => string | null
    setMeetingTitle: (value: string) => void
    setCallDuration: (value: number) => void
    getCallDuration: () => number | null
    getIsUserKickedOrBanned: () => boolean
    cleanUp: () => void
    setUp: () => void
}
const state = createState<StateValues>(getStartValues())

const useStateWrapper = (state: State<StateValues>): MeetingController => {
    const meetingManager = useMeetingManager()
    const { setIsAudioVideoSettingsV2Open, setIsPreMeetingScreenOpen } = useAppState() // eslint-disable-line
    const { roster } = useRosterState()
    const attendeesCount = Object.values(roster).length
    const meetingInvitation = useMeetingInvitation()
    const history = useHistory()
    const dispatch = useNotificationDispatch()
    const userId = useLoggedInState().user()?.profileId

    const setUp = () => {
        state.merge({ isMeetingActive: true, isSwitchingRoom: false, kickOrBanMessage: null, isUserKickedOrBanned: false })
    }

    const cleanUp = () => {
        state.merge({
            role: null,
            externalMeetingId: null,
            externalUserId: null,
            meetingKind: null,
            maxAttendees: 5,
            maxDurationSeconds: null,
            timeLimitChanged: false,
            meetingTimeLeft: null,
            isMeetingActive: false,
            egMeetingStatus: null,
            meetingTitle: null,
            callDuration: null,
            isConferenceOverlayVisible: false
        })
    }

    const startMeeting = async () => {
        await meetingManager.start().then(() => {
            setUp()
            if (meetingManager.meetingStatus === MeetingStatus.Succeeded) {
                meetingInvitation.resetInvitationTypes()
            }
            insertMeetingRoomAttendance(state.value.externalMeetingId || "", userId || "")
        })
    }

    const leaveMeeting = async (isSwitchingRoom?: boolean) => {
        if (state.value.meetingKind === "call" && attendeesCount <= 2) {
            meetingManager.audioVideo?.realtimeSendDataMessage(state.value.externalMeetingId?.slice(0, 10) || "", {
                type: DataMessageType.CALL_ENDED_FOR_ALL
            })
        }
        meetingManager.audioVideo?.stop()

        await Promise.all([
            await leaveRoom(state.value.externalMeetingId, state.value.externalUserId),
            await meetingManager.leave(),
            await removeMeetingRoomAttendance(userId || "")
        ])
            .then(() => {
                /**
                 * reroute the user to the "Meeting ended" screen only if
                 * is not switching the room or if the user is not in PiP
                 * mode
                 *  */
                if (isSwitchingRoom || state.value.isConferenceOverlayVisible) {
                    return
                } else {
                    history.push(meetingEndedRoute)
                }
            })
            .catch((e: any) => {
                console.log("Error while leaving a room", e)
            })
            .finally(() => {
                // cleanup on leave
                cleanUp()
            })
    }

    return {
        initMeeting: async (externalMeetingId: string, externalUserId: string) => {
            try {
                let backendResponse = (await createOrJoinMeeting(externalMeetingId, externalUserId)) as ChimeMeetingData

                // if (!backendResponse.attendee || !backendResponse.chime || !backendResponse.meeting) {
                //     throw new Error("error initializng meeting on messe backend")
                // }

                if ((backendResponse as unknown as BackendServiceError).httpStatus) {
                    throw backendResponse
                }

                if (backendResponse.chime && backendResponse.attendee && backendResponse.meeting) {
                    const meetingSessionConfiguration = new MeetingSessionConfiguration(
                        backendResponse.chime.Meeting,
                        backendResponse.chime.Attendee
                    )

                    state.merge({
                        role: backendResponse.attendee.role,
                        externalMeetingId,
                        externalUserId,
                        meetingKind: backendResponse.meeting.meetingKind,
                        maxAttendees: backendResponse.meeting.maxAttendees,
                        maxDurationSeconds: backendResponse.meeting.maxDurationSeconds,
                        timeLimitChanged: backendResponse.meeting.timeLimitChanged,
                        meetingTimeLeft: backendResponse.meeting.remainingDurationMillis,
                        egMeetingStatus: null
                    })

                    try {
                        await removeRaisedHand(externalUserId)
                        await meetingManager.join(meetingSessionConfiguration)
                        if (state.value.meetingKind === "call") {
                            setIsAudioVideoSettingsV2Open(false)
                            // setIsPreMeetingScreenOpen(false)
                            startMeeting()
                        } else {
                            setIsAudioVideoSettingsV2Open(true)
                            // setIsPreMeetingScreenOpen(true)
                        }
                    } catch (error: any) {}

                    // TODO: Here should all subscriptions for data messages occur

                    // 1. Subscriptions based on user id
                    meetingManager.audioVideo?.realtimeSubscribeToReceiveDataMessage(
                        state.value.externalUserId?.slice(0, 10) || "",
                        async (dataMessage: DataMessage) => {
                            const messageData = dataMessage.json()
                            const messageDataType: DataMessageType = messageData.type
                            if (!messageDataType) return
                            switch (messageDataType) {
                                case DataMessageType.MUTE:
                                    meetingManager.audioVideo?.realtimeMuteLocalAudio()
                                    dispatch({
                                        type: ActionType.ADD,
                                        payload: {
                                            severity: Severity.INFO,
                                            message: branding.conferenceTexts.mutedByMod,
                                            autoCloseDelay: 3000
                                        }
                                    })
                                    break
                                case DataMessageType.STOP_SCREENSHARE:
                                    meetingManager.audioVideo?.stopContentShare()
                                    dispatch({
                                        type: ActionType.ADD,
                                        payload: {
                                            severity: Severity.INFO,
                                            message: branding.conferenceTexts.screenShareStoppedByMod,
                                            autoCloseDelay: 3000
                                        }
                                    })
                                    break
                                case DataMessageType.RAISEHAND:
                                    removeRaisedHand(state.value.externalUserId || "")
                                    dispatch({
                                        type: ActionType.ADD,
                                        payload: {
                                            severity: Severity.INFO,
                                            message: branding.conferenceTexts.loweredHandByMod,
                                            autoCloseDelay: 3000
                                        }
                                    })
                                    break
                                case DataMessageType.KICK:
                                    state.merge({ egMeetingStatus: EGMeetingStatus.Kicked, kickOrBanMessage: messageData.data })
                                    meetingManager.audioVideo?.stop()
                                    meetingManager.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(
                                        state.value.externalUserId || ""
                                    )
                                    meetingManager.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(
                                        state.value.externalMeetingId || ""
                                    )
                                    await kick(state.value.externalMeetingId!, state.value.externalUserId!, messageData.data)
                                    state.merge({ isUserKickedOrBanned: true })
                                    break
                                case DataMessageType.BAN:
                                    state.merge({ egMeetingStatus: EGMeetingStatus.Banned, kickOrBanMessage: messageData.data })
                                    meetingManager.audioVideo?.stop()
                                    meetingManager.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(
                                        state.value.externalUserId?.slice(0, 10) || ""
                                    )
                                    meetingManager.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(
                                        state.value.externalMeetingId?.slice(0, 10) || ""
                                    )
                                    await ban(state.value.externalMeetingId!, state.value.externalUserId!, messageData.data)
                                    state.merge({ isUserKickedOrBanned: true })
                                    break
                            }
                        }
                    )
                    // 2. Subscriptions based on external meeting id
                    meetingManager.audioVideo?.realtimeSubscribeToReceiveDataMessage(
                        state.value.externalMeetingId?.slice(0, 10) || "",
                        async (dataMessage: DataMessage) => {
                            const messageData = dataMessage.json()
                            const messageDataType: DataMessageType = messageData.type
                            if (!messageDataType) return
                            switch (messageDataType) {
                                case DataMessageType.CALL_ENDED_FOR_ALL:
                                    await leaveMeeting()
                                    break
                            }
                        }
                    )

                    if (state.value.timeLimitChanged) {
                        const delay = 5000
                        setTimeout(() => {
                            meetingManager.audioVideo?.realtimeSendDataMessage(
                                state.value.externalMeetingId?.slice(0, 10) || "",
                                {
                                    type: DataMessageType.TIMELIMITCHANGED,
                                    meetingTimeLeft: state.value.meetingTimeLeft! - delay,
                                    maxDurationSeconds: state.value.maxDurationSeconds
                                }
                            )
                        }, delay)
                    }
                }
            } catch (error: any) {
                if ((error as BackendServiceError).httpStatus) {
                    // Custom error code from the backend.
                    if (error.httpStatus === 444) {
                        if (error.responseJson?.errorCode === "meetingFull") {
                            state.merge({ egMeetingStatus: EGMeetingStatus.Full })
                            return
                        } else if (error.responseJson?.errorCode === "meetingTimeUp") {
                            state.merge({ egMeetingStatus: EGMeetingStatus.TimeUp })
                            return
                        }
                    } else if (error.httpStatus === 401) {
                        if (error.responseJson?.errorCode === "banned") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.Banned
                            })
                            return
                        } else if (error.responseJson?.errorCode === "channelIsLive") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.GreenRoomLive
                            })
                            return
                        } else if (error.responseJson?.errorCode === "noAuth") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoAuth
                            })
                            return
                        }
                    } else if (error.httpStatus === 400) {
                        if (error.responseJson?.errorCode === "BREAKOUTaccessDenied") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoModeratorInRoom
                            })
                            return
                        }
                        if (error.responseJson?.errorCode === "noConferenceRoomAccess") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoConferenceRoomAccess
                            })
                            return
                        }
                        if (error.responseJson?.errorCode === "CONFERENCE_ROOMaccessDenied") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoModeratorInRoom
                            })
                            return
                        }
                        if (error.responseJson?.errorCode === "noUserConferenceRoomAccesGranted") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoConferenceRoomAccessGranted
                            })
                            return
                        }
                        if (error.responseJson?.errorCode === "noConferenceRoom") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoConferenceRoom
                            })
                            return
                        }
                        if (error.responseJson?.errorCode === "noSuchEventDate") {
                            state.merge({
                                egMeetingStatus: EGMeetingStatus.NoEventdate
                            })
                            return
                        }
                    }
                }
                // state.merge({ initMeetingError: error.message })
            }
        },
        startMeeting: startMeeting,
        leaveMeeting: leaveMeeting,
        getExternalMeetingId: () => {
            return state.value.externalMeetingId
        },
        getExternalUserId: () => {
            return state.value.externalUserId
        },
        getIsMod: () => {
            return state.value.role === "moderator"
        },
        getInitMeetingError: () => {
            return state.value.initMeetingError
        },
        getEGMeetingStatus: () => {
            return state.value.egMeetingStatus
        },
        setEGMeetingStatus: (egMeetingStatus: EGMeetingStatus) => {
            state.merge({ egMeetingStatus })
        },
        getMeetingKind: () => {
            return state.value.meetingKind
        },
        getMeetingTimeLeft: () => {
            return state.value.meetingTimeLeft
        },
        getTimeLimitChanged: () => {
            return state.value.timeLimitChanged
        },
        getMaxAttendees: () => {
            return state.value.maxAttendees
        },
        getHasMaxAttendees: () => {
            return Object.values(roster).length >= state.value.maxAttendees
        },
        getIsMeetingChangeAccepted: () => {
            return state.value.meetingChangeAccepted
        },
        setIsMeetingChangeAccepted: (accepted: boolean) => {
            state.meetingChangeAccepted.merge(accepted)
        },
        getIsMeetingActive: () => {
            return state.value.isMeetingActive
        },
        getIsSwitchingRoom: () => {
            return state.value.isSwitchingRoom
        },
        setIsSwitchingRoom: (value: boolean) => {
            state.isSwitchingRoom.merge(value)
        },
        isConferenceOverlayVisible: () => {
            return state.value.isConferenceOverlayVisible
        },
        setShowConferenceOverlay: (value: boolean) => {
            state.isMeetingMinimized.merge(value)
        },
        setKickOrBanMessage: (value: string) => {
            state.merge({ kickOrBanMessage: value })
        },
        getKickOrBanMessage: () => {
            return state.value.kickOrBanMessage
        },
        setIsConferenceOverlayVisible: (value: boolean) => {
            state.isConferenceOverlayVisible.merge(value)
        },
        setMeetingTitle: (value: string) => {
            state.merge({ meetingTitle: value })
        },
        getMeetingTitle: () => {
            return state.value.meetingTitle
        },
        setCallDuration: (value: number) => {
            state.merge({ callDuration: value })
        },
        getCallDuration: () => {
            return state.value.callDuration
        },
        getIsUserKickedOrBanned: () => {
            return state.value.isUserKickedOrBanned
        },
        setUp: setUp,
        cleanUp: cleanUp
    }
}
/**
 * This controller is used for gluing the sdk and our backend together. We initialize here the meeting
 * via the meetingManager and our backend service in the same time. Same goes for leaving the meeting.
 * I recomend to do other stuff directly via the meetingManager, but starting and leaving the
 * meeting with this controller.
 */
export const useMeetingController = (): MeetingController => useStateWrapper(useState(state))
